import { ActionTree } from 'vuex';
import { Media, MediaState, MediaWrapper } from './types';
import { RootState } from '../../store';

import * as mutations from './mutations';

export const LOAD_MEDIA = 'loadMedia';
export const TOGGLE = 'toggle';
export const PLAY = 'play';
export const PAUSE = 'pause';
export const NEXT = 'next';
export const PLAY_MEDIA = 'playMedia';
export const PREVIOUS = 'previous';
export const TOGGLE_PLAYLIST = 'togglePlaylist';
export const CLOSE_PLAYLIST = 'closePlaylist';
export const REMOVE_MEDIA = 'removeMedia';

export const actions: ActionTree<MediaState, RootState> = {
  [LOAD_MEDIA]({ commit }, media: MediaWrapper): void {
    commit(mutations.LOAD_MEDIA, media);
  },
  [TOGGLE]({ commit }): void {
    commit(mutations.TOGGLE);
  },
  [PLAY]({ commit }): void {
    commit(mutations.PLAY);
  },
  [PAUSE]({ commit }): void {
    commit(mutations.PAUSE);
  },
  [NEXT]({ commit }): void {
    commit(mutations.NEXT);
  },
  [PLAY_MEDIA]({ commit }, media: Media): void {
    commit(mutations.PLAY_MEDIA, media);
  },
  [PREVIOUS]({ commit }): void {
    commit(mutations.PREVIOUS);
  },
  [TOGGLE_PLAYLIST]({ commit }): void {
    commit(mutations.TOGGLE_PLAYLIST);
  },
  [CLOSE_PLAYLIST]({ commit }): void {
    commit(mutations.CLOSE_PLAYLIST);
  },
  [REMOVE_MEDIA]({ commit }): void {
    commit(mutations.REMOVE_MEDIA);
  },
};
