import { GetterTree } from 'vuex';
import { County, DefinitionState } from './types';
import { RootState } from '../../store';
import { LoadingState } from '../utils';

export const getters: GetterTree<DefinitionState, RootState> = {
  counties(state: DefinitionState): LoadingState<County[]> {
    return state.counties;
  },
};
