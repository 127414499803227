





















































































import { Component, Mixins } from 'vue-property-decorator';
import { FieldStateMixin } from '../../utils/FieldStateMixin';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { Action, Getter } from 'vuex-class';
import { County } from '../../modules/definitions/types';
import InvalidForm from '../shared/InvalidForm.vue';
import { LoadingState } from '../../modules/utils';
import moment from 'moment';
import { User } from '../../models/Auth';

@Component({
  components: { InvalidForm },
  validations: {
    form: {
      birthDate: { required },
      gender: { required },
      county: { required },
      city: { required },
      phone: {},
    },
  },
})
export default class FinalizeAccount extends Mixins(FieldStateMixin) {
  public form: FinalizeAccountRequest = {
    birthDate: null,
    gender: null,
    county: null,
    city: null,
    phone: null,
  };
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation } & FinalizeAccountRequest;
    };
  @Getter('counties') public counties: LoadingState<County[]>;
  @Getter('userDetails') public userDetails?: User;
  @Action('userDetails') public loadUserDetails: () => void;
  public busy: boolean = false;

  public mounted() {
    this.$bvModal.show('finalizeAccount');
  }

  public finalizeAccount(event: Event) {
    event.preventDefault();
    this.handleSubmit();
  }

  public get datepickerInputClass(): string {
    if (this.fieldState(this.$v.form.birthDate) === false) {
      return 'is-invalid';
    } else if (this.fieldState(this.$v.form.birthDate) === true) {
      return 'is-valid';
    }
  }

  public handleSubmit() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.busy = true;
      this.$http
        .post(this.$apiRoutes.account.finalize, {
          birth_date: moment(this.form.birthDate).format('YYYY-MM-DD'),
          sex: this.form.gender,
          judet: this.form.county,
          localitate: this.form.city,
          phone: this.form.phone,
        })
        .then(() => {
          this.$root.$bvToast.toast(
            'Detaliile contului au fost actualizate cu succes',
            { variant: 'success', autoHideDelay: 5000 },
          );
          this.loadUserDetails();
        })
        .finally(() => (this.busy = false));
    }
  }
}

interface FinalizeAccountRequest {
  birthDate?: string;
  gender?: string;
  county?: string;
  city?: string;
  phone?: string;
}
