






































































































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CookiePolicy extends Vue {
  public checked: string[] = ['essential'];
  public fields: Array<{ key: string; label: string }> = [
    { key: 'name', label: 'Nume' },
    { key: 'purpose', label: 'Rol' },
    { key: 'expiry', label: 'Expiră' },
    { key: 'type', label: 'Tip' },
    { key: 'provider', label: 'Furnizor' },
  ];
  public essentialCookies: Cookie[] = [
    {
      name: '__cm_tkn',
      purpose:
        'Folosit pentru a autentifica utilizatorul și a-i permite acestuia accesul la zonele restrânse.',
      expiry: '30 zile',
      type: 'HTTP',
      provider: {
        name: 'Cantus Mundi',
        url: '/cookie-policy',
      },
    },
    {
      name: '__cookie_consent',
      purpose:
        'Folosit pentru a stoca preferințele privind politica de cookieuri',
      expiry: '30 zile',
      type: 'HTTP',
      provider: {
        name: 'Cantus Mundi',
        url: '/cookie-policy',
      },
    },
  ];
  public statisticsCookies: Cookie[] = [
    {
      name: '_ga',
      purpose: 'Folosit pentru a deosebi utilizatorii',
      expiry: '2 ani',
      type: 'HTTP',
      provider: {
        name: 'Google Analytics',
        url:
          'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage',
      },
    },
    {
      name: '_gid',
      purpose: 'Folosit pentru a deosebi utilizatorii',
      expiry: '24 ore',
      type: 'HTTP',
      provider: {
        name: 'Google Analytics',
        url:
          'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage',
      },
    },
    {
      name: '_gat',
      purpose: 'Folosit pentru a regula rata de cereri la server',
      expiry: '1 minut',
      type: 'HTTP',
      provider: {
        name: 'Google Analytics',
        url:
          'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage',
      },
    },
  ];

  public acceptAll() {
    this.$cookies.set(
      '__cookie_consent',
      JSON.stringify(['essential', 'statistics']),
    );
    this.$router.go(0);
  }

  public acceptSelected() {
    this.$cookies.set('__cookie_consent', JSON.stringify(this.checked));
    this.$router.go(0);
  }

  public acceptNone() {
    this.$cookies.set('__cookie_consent', JSON.stringify(['essential']));
    this.$router.go(0);
  }
}

interface Cookie {
  name: string;
  purpose: string;
  expiry: string;
  type: string;
  provider: Provider;
}

interface Provider {
  name: string;
  url: string;
}
