










































































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import AddNewChoirStepTwo from '../../../../components/account/addChoir/AddNewChoirStepTwo.vue';
import AddNewChoirStepOne from '../../../../components/account/addChoir/AddNewChoirStepOne.vue';
import { MediaWrapper } from '../../../../modules/media/types';
import { AudioBook, AudioBookMp3, Score } from '../../../../models/Library';

@Component({
  components: { AddNewChoirStepTwo, AddNewChoirStepOne },
})
export default class Audiobooks extends Vue {
  public $refs!: Vue['$refs'] & {
    'add-choir-modal': any;
  };

  @Action('loadMedia') public loadMedia: (media: MediaWrapper) => {};

  public audioBooks: AudioBook[] = [];
  public filter: string = '';
  public downloading: number[] = [];
  public page: number = 1;
  public perPage = 10;
  public items = 0;

  public fields = [
    {
      key: 'name',
      label: 'Nume',
      sortable: true,
      thClass: 'all-audioBooks-table-head',
    },
    {
      key: 'mp3s.length',
      label: 'Nr Melodii',
      sortable: true,
      thClass: 'all-audioBooks-table-head',
    },
    { key: 'action', label: 'Acțiuni', thClass: 'all-audioBooks-table-head' },
  ];

  public mounted() {
    this.$http
      .get<AudioBook>(this.$apiRoutes.audioBooks.list)
      .then((response: any) => {
        this.audioBooks = response.data;
        this.items = this.audioBooks.length;
      });
  }

  public downloadFile(audioBook: AudioBook) {
    this.downloading = [...this.downloading, audioBook.id];
    this.$http
      .post(this.$apiRoutes.download('audiobook', audioBook.id), null, {
        responseType: 'blob',
      })
      .then((response: any) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${audioBook.name}.zip`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .finally(() => {
        this.downloading = this.downloading.filter(
          (item: number) => item !== audioBook.id,
        );
      });
  }

  public isDownloading(audioBook: AudioBook) {
    return this.downloading.find((item: number) => item === audioBook.id);
  }

  public onFiltered(filteredItems: AudioBook[]) {
    this.items = filteredItems.length;
  }

  private selectAudiobook(audiobook: AudioBook) {
    this.loadMedia({
      name: audiobook.name,
      media: audiobook.mp3s.map((item: AudioBookMp3) => {
        return {
          name: item.name,
          file: item.file,
          type: 'audio',
        };
      }),
    });
  }
}
