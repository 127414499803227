export class ObjectiveItem {
  public title: string;
  public description: string;
  public image: string;

  constructor(data: { title: string; description: string; image: string }) {
    this.title = data.title;
    this.description = data.description;
    this.image = data.image;
  }
}

export interface ObjectiveNumber {
  title: string;
  description: string;
  target: number;
  currentValue: number;
}

export interface ObjectiveNumberResponse {
  title: string;
  description: string;
  target: number;
  current_value: number;
}
