var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-body',[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Formular înscriere")])]),_c('b-alert',{attrs:{"variant":"danger","show":_vm.serverError}},[_c('strong',[_vm._v("Formularul nu a putut fi salvat!")]),_c('br'),_vm._v(" Te rugăm să încerci mai târziu.")]),_c('b-alert',{attrs:{"variant":"success","show":_vm.success}},[_c('strong',[_vm._v("Felicitări!")]),_vm._v(" Înscrierea ta a fost salvată cu succes!"),_c('br'),_vm._v("În cel mai scurt timp te vom contacta pentru detalii.")]),_c('b-form-group',{attrs:{"label":"Nume","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.name),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.name, "$model", $$v)},expression:"$v.form.name.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.name}})],1),_c('b-form-group',{attrs:{"label":"Adresă de e-mail","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.email),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", $$v)},expression:"$v.form.email.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.email}})],1),_c('b-form-group',{attrs:{"label":"Telefon","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.phone),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.phone.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.phone, "$model", $$v)},expression:"$v.form.phone.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.phone}})],1),_c('b-form-group',{attrs:{"label":"Vârstă","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      }}},[_c('b-input-group',[_c('b-input',{attrs:{"type":"range","min":"4","max":"99","step":"1","state":_vm.fieldState(_vm.$v.form.age),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.age.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.age, "$model", $$v)},expression:"$v.form.age.$model"}}),_c('b-input-group-prepend',[_c('b-input-group-text',[_vm._v(_vm._s(_vm.form.age))])],1)],1),_c('invalid-form',{attrs:{"field":_vm.$v.form.age}})],1),_c('b-form-group',{attrs:{"label":"Ultima unitate de învățământ absolvită","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.school),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.school.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.school, "$model", $$v)},expression:"$v.form.school.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.school}})],1),_c('b-form-group',{attrs:{"label":"Unitatea școlară sau instituția/organizația unde lucrați","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.workplace),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.workplace.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.workplace, "$model", $$v)},expression:"$v.form.workplace.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.workplace}})],1),_c('b-form-group',{attrs:{"label":"Specificați ce competențe în domeniul muzical dețineți","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.knowledge),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.knowledge.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.knowledge, "$model", $$v)},expression:"$v.form.knowledge.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.knowledge}}),_c('b-form-text',[_vm._v("cunoașterea notelor muzicale, citire partituri, cunoașterea duratelor muzicale etc.")])],1),_c('b-form-group',{attrs:{"label":"Specificați dacă aveți experiență ca dirijor sau coordonator de cor de copii","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.experience),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.experience.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.experience, "$model", $$v)},expression:"$v.form.experience.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.experience}})],1),_c('b-form-group',{attrs:{"label":"Alegeți sesiunea","label-cols-md":"4","label-class":{
        'text-md-right': true,
        'font-weight-bold': true,
      },"description":"Tineți apăsat pe tasta CTRL pentru a selecta mai multe opțiuni"}},[_c('b-form-select',{attrs:{"multiple":"","select-size":3,"state":_vm.fieldState(_vm.$v.form.workshops),"disabled":_vm.loading || _vm.busy},model:{value:(_vm.$v.form.workshops.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.workshops, "$model", $$v)},expression:"$v.form.workshops.$model"}},_vm._l((_vm.workshops),function(workshop){return _c('b-form-select-option',{key:workshop.id,attrs:{"value":workshop.id}},[_vm._v(_vm._s(workshop.title)+" - "+_vm._s(_vm._f("formatDate")(workshop.date,'LLL')))])}),1),_c('invalid-form',{attrs:{"field":_vm.$v.form.workshops}})],1),_c('form-agreement',{ref:"agreement",model:{value:(_vm.form.agreed),callback:function ($$v) {_vm.$set(_vm.form, "agreed", $$v)},expression:"form.agreed"}}),_c('b-form-group',{staticClass:"text-center mt-3"},[_c('b-button',{staticClass:"text-uppercase align-self-center",attrs:{"variant":"danger"},on:{"click":_vm.submit}},[_vm._v("Trimite")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }