





























































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import {
  email,
  maxLength,
  minLength,
  numeric,
  required,
} from 'vuelidate/lib/validators';
import { Validation, validationMixin } from 'vuelidate';
import { AddNewChoir } from '../../../models/AddNewChoir';
import { FieldStateMixin } from '../../../utils/FieldStateMixin';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import InvalidForm from '../../shared/InvalidForm.vue';

@Component({
  components: { InvalidForm },
  validations: {
    choir: {
      contact: { required, minLength: minLength(5) },
      phone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      email: { required, email },
    },
  },
  mixins: [validationMixin],
})
export default class AddNewChoirStepTwo extends Mixins(FieldStateMixin) {
  @Prop() public choir: AddNewChoir;
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation } & AddNewChoir;
    };

  public isValid(): boolean {
    this.$v.$touch();
    return !this.$v.$invalid;
  }
}
