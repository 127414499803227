














import { Component, Vue } from 'vue-property-decorator';
import SectionHeader from '../../components/shared/SectionHeader.vue';

@Component({
  components: {
    SectionHeader,
  },
})
export default class CantusMundiBucharest extends Vue {}
