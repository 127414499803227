































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Member } from '../../../models/Members';
import { Action, Getter } from 'vuex-class';
import { LoadingState } from '../../../modules/utils';
import { County } from '../../../modules/definitions/types';
import * as userGetters from '../../../modules/user/getters';
import * as userActions from '../../../modules/user/actions';
import { Choir } from '../../../models/Choir';

@Component
export default class EditMemberModal extends Vue {
  public get countyName() {
    const county = this.counties.data.find(
      (c: County) => c.value === this.selectedMember.county,
    );
    if (county) {
      return county.text;
    }
    return '';
  }

  public get isApproved(): boolean {
    return this.selectedMember && this.selectedMember.status;
  }

  public get isConductor(): boolean {
    return this.selectedMember.type === '1';
  }
  @Getter('counties') public counties: LoadingState<County[]>;
  @Getter(userGetters.SELECTED_CHOIR) public choir: Choir;
  @Action(userActions.LOAD_CHOIR_DETAILS) public loadChoir: (
    selectedChoirId: number,
  ) => void;

  public busy: boolean = false;
  @Prop() private selectedMember: Member;

  public performAction(action: string) {
    let request;
    this.busy = true;
    switch (action) {
      case 'remove':
        request = this.$http.post(
          this.$apiRoutes.choir.removeMember(this.choir.id),
          {
            user_id: this.selectedMember.id,
          },
        );
        break;
      case 'approve':
        request = this.$http.post(
          this.$apiRoutes.choir.approveMember(this.choir.id),
          {
            user_id: this.selectedMember.id,
          },
        );
        break;
      case 'makeConductor':
        request = this.$http.post(
          this.$apiRoutes.choir.makeConductor(this.choir.id),
          {
            user_id: this.selectedMember.id,
          },
        );
        break;
      case 'makeChoirMember':
        request = this.$http.post(
          this.$apiRoutes.choir.makeMember(this.choir.id),
          {
            user_id: this.selectedMember.id,
          },
        );
        break;
      default:
        this.busy = false;
    }
    if (!!request) {
      request
        .then(() => {
          this.loadChoir(this.choir.id);
        })
        .finally(() => (this.busy = false));
    }
  }
}
