







































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import moment from 'moment';
import ListActionComp from './ListActionComp.vue';
import ListItem from './ListItem.vue';
import { User } from '../../../models/Auth';
import BoxHeader from './BoxHeader.vue';
import { Choir } from '../../../models/Choir';
import { Message } from '../../../models/Dashboard';

@Component({
  components: { BoxHeader, ListActionComp, ListItem },
})
export default class Messages extends Vue {
  @Getter('userDetails') public userDetails: User;
  @Getter('selectedChoir') public selectedChoir: Choir;

  public messages: Message[] = [];
  public result: number;
  public loading: boolean = true;

  @Watch('selectedChoir', { immediate: true })
  public onSelectedChoirUpdate() {
    this.messages = [];
    this.loading = true;
    if (this.selectedChoir.id === undefined) {
      return;
    }
    this.$http
      .get(this.$apiRoutes.dashboard.messages(this.selectedChoir.id))
      .then(
        (response: any) => {
          this.messages = response.data;
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
        },
      );
  }

  public getTime(date: Date) {
    this.result = moment().diff(moment(date), 'd');
    return this.result + 'd';
    // if (this.result < 1) {
    //     console.log(moment().diff(moment(date), 'm'));
    // }
    // else if (this.result > 1 && this.result < 31) {
    //     console.log(moment().diff(moment(date), 'd'));
    // }
    // else if (this.result > 31 && this.result < 365) {
    //     console.log(moment().diff(moment(date), 'M'));
    // }
    // else {
    //     console.log(moment().diff(moment(date), 'y'));
    // }

    // if (this.result > 7) {
    //     console.log("Output: date is more than 1 week behind")
    // } else if (this.result > 1) {
    //     console.log("Output: date is more than 1 day but less than 1 week behind")
    // } else {
    //     console.log("Output: date is less  than 1 day behind")
    // }
  }
}
