

















import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import EditMemberModal from '../../../components/account/mychoir/EditMemberModal.vue';
import EditChoirModal from '../../../components/account/mychoir/EditChoirModal.vue';
import MembersTable from '../../../components/account/mychoir/MembersTable.vue';
import MyChoirSummaryBox from '../../../components/account/mychoir/MyChoirSummaryBox.vue';
import ChoirPresentationBox from '../../../components/account/mychoir/ChoirPresentationBox.vue';
import AddChoir from '../../../components/account/addChoir/AddChoir.vue';
import SummaryBox from '../../../components/account/SummaryBox.vue';
import { RedirectMixin } from '../../../utils/RedirectMixin';

@Component({
  components: {
    EditMemberModal,
    EditChoirModal,
    AddChoir,
    MyChoirSummaryBox,
    MembersTable,
    ChoirPresentationBox,
    SummaryBox,
  },
})
export default class MyChoir extends Mixins(RedirectMixin) {}
