














import { Component, Prop, Vue } from 'vue-property-decorator';
import ListItem from './ListItem.vue';

@Component({
  components: { ListItem },
})
export default class ListActionComp extends Vue {
  @Prop({ required: true }) public loading: boolean;
  @Prop({ required: true }) public emptyDataText: string;
}
