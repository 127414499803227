import { County, DefinitionState } from './types';
import { LoadingState } from '../utils';
import { Module } from 'vuex';
import { RootState } from '../../store';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export const state: DefinitionState = {
  counties: new LoadingState<County[]>([]),
};

const namespaced: boolean = false;

export const definitions: Module<DefinitionState, RootState> = {
  namespaced,
  actions,
  mutations,
  state,
  getters,
};
