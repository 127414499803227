




import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SummaryBox from '../SummaryBox.vue';
import { Choir } from '../../../models/Choir';

import * as userGetters from '../../../modules/user/getters';
import { SummaryBoxContent } from '../../../models/SymmaryBox';
import { AxiosResponse } from 'axios';

@Component({
  components: { SummaryBox },
})
export default class MyChoirSummaryBox extends Vue {
  private static parseItems(items: ChoirSummaryResponse): SummaryBoxContent[] {
    const result: SummaryBoxContent[] = [];
    Object.keys(items).map((key: string) => {
      let type: string = null;
      let order: number;
      switch (key) {
        case 'members':
          type = 'Membri';
          order = 2;
          break;
        case 'conductors':
          type = 'Dirijori';
          order = 1;
          break;
        case 'waiting':
          type = 'Membri în așteptare';
          order = 3;
          break;
      }
      if (type) {
        result.push({ type, order, count: items[key] });
      }
    });
    return result;
  }

  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;
  @Getter(userGetters.IS_CONDUCTOR) public isConductor: boolean;

  public loading: boolean = true;
  public items: SummaryBoxContent[] = [];

  @Watch('selectedChoir', { immediate: true }) public onSelectedChoirUpdate() {
    if (this.selectedChoir && this.selectedChoir.id) {
      this.loading = true;
      this.$http
        .get<ChoirSummaryResponse>(
          this.$apiRoutes.dashboard.summary(this.selectedChoir.id),
        )
        .then(
          (response: AxiosResponse<ChoirSummaryResponse>) => {
            this.items = MyChoirSummaryBox.parseItems(response.data);
          },
          (error: any) => {},
        )
        .finally(() => (this.loading = false));
    }
  }

  public mounted() {
    this.items = MyChoirSummaryBox.parseItems({
      waiting: 0,
      members: 0,
      conductors: 0,
    });
  }
}

interface ChoirSummaryResponse {
  waiting: number;
  members: number;
  conductors: number;
}
