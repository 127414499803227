
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { SummaryBoxContent } from '../../models/SymmaryBox';

@Component
export default class SummaryBox extends Vue {
  @Prop({ required: true }) public items: SummaryBoxContent[];
  @Prop({}) public loading: boolean;

  public get ordered(): SummaryBoxContent[] {
    return this.items.sort(
      (item: SummaryBoxContent, other: SummaryBoxContent) =>
        item.order - other.order,
    );
  }
}
