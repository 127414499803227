

































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FullWidthText extends Vue {
  @Prop() private title!: string;
  @Prop() private subtitle!: string;
  @Prop() private description!: string;
  @Prop() private image!: string;
  @Prop() private imagePosition!: string;
}
