



















































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { email, required } from 'vuelidate/lib/validators';
import InvalidForm from '../../../components/shared/InvalidForm.vue';
import { FieldStateMixin } from '../../../utils/FieldStateMixin';

@Component({
  components: { InvalidForm },
  validations: {
    email: { required, email },
  },
})
export default class PasswordReset extends Mixins(FieldStateMixin) {
  public email: string = null;

  public error: boolean = false;
  public success: boolean = false;
  public loading: boolean = false;

  public resetPassword(event: Event) {
    event.preventDefault();
    this.loading = true;
    this.$http.post('api/auth/reset_password/', { email: this.email }).then(
      (result: any) => {
        this.loading = false;
        this.success = true;
        this.email = null;
      },
      (error: any) => {
        this.loading = false;
        this.error = true;
        this.email = null;
      },
    );
  }
}
