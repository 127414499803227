import _Vue from 'vue';

const apiPrefix = '/api';
const accountPrefix = `${apiPrefix}/account`;
const authPrefix = `${apiPrefix}/auth`;
const choirPrefix = `${apiPrefix}/choir`;
const dashboardPrefix = `${apiPrefix}/dashboard`;
const eventPrefix = `${apiPrefix}/event`;
const newsPrefix = `${apiPrefix}/news`;
const audioBookPrefix = `${apiPrefix}/audio-book`;
const messagingPrefix = `${apiPrefix}/messaging`;
const videoLessonsPrefix = `${apiPrefix}/video-lessons`;
const signupPrefix = `${apiPrefix}/signup`;

class AccountRoutes {
  public static joinChoir = `${accountPrefix}/join_choir/`;
  public static removeChoir = `${accountPrefix}/remove_choir/`;
  public static finalize = `${accountPrefix}/finalize/`;
  public static getCounties = `${accountPrefix}/counties/`;
  public static avatar = `${accountPrefix}/avatar/`;
  public static edit = `${accountPrefix}/edit/`;
}

class AuthRoutes {
  public static register = `${authPrefix}/register/`;
}

class ChoirRoutes {
  public static loadAll = `${choirPrefix}/`;
  public static createChoir = `${choirPrefix}/`;

  public static details(choirId: number): string {
    return `${choirPrefix}/${choirId}/`;
  }

  public static image(choirId: number): string {
    return `${choirPrefix}/${choirId}/image/`;
  }

  public static members(choirId: number) {
    return `${choirPrefix}/${choirId}/members/`;
  }

  public static approveMember(choirId: number) {
    return `${choirPrefix}/${choirId}/approve_member/`;
  }

  public static removeMember(choirId: number) {
    return `${choirPrefix}/${choirId}/remove_member/`;
  }

  public static makeConductor(choirId: number) {
    return `${choirPrefix}/${choirId}/make_conductor/`;
  }

  public static makeMember(choirId: number) {
    return `${choirPrefix}/${choirId}/make_member/`;
  }
}

class DashboardRoutes {
  public static audiobook = `${dashboardPrefix}/audiobook/`;
  public static scores = `${dashboardPrefix}/scores/`;

  public static summary(choirId: number) {
    return `${dashboardPrefix}/${choirId}/summary/`;
  }

  public static events(choirId: number) {
    return `${dashboardPrefix}/${choirId}/events/`;
  }

  public static messages(choirId: number) {
    return `${dashboardPrefix}/${choirId}/messages/`;
  }

  public static pending(choirId: number) {
    return `${dashboardPrefix}/${choirId}/pending/`;
  }
}

class EventRoutes {
  public static createEvent = `${eventPrefix}/`;

  public static eventsByChoir(choirId: number) {
    return `${eventPrefix}/?choir_id=${choirId}`;
  }
}

// tslint:disable-next-line:max-classes-per-file
class NewsRoutes {
  public static list = `${newsPrefix}/`;
  public static categories = `${newsPrefix}/categories/`;

  public static details(slug: string) {
    return `${newsPrefix}/${slug}/`;
  }
}

// tslint:disable-next-line:max-classes-per-file
class AudioBookRoutes {
  public static list = `${audioBookPrefix}/`;
  public static details(id: number) {
    return `${audioBookPrefix}/${id}/`;
  }
}

class MessagingRoutes {
  public static contact = `${messagingPrefix}/contact/`;
}

class VideoLessonsRoutes {
  public static list = `${videoLessonsPrefix}/`;
}

class SignUpRoutes {
  public static audition = `${signupPrefix}/auditions/`;
  public static workshops = `${signupPrefix}/workshops/`;
  public static campaign = `${signupPrefix}/campaign/`;
}

// tslint:disable-next-line:max-classes-per-file
export class ApiRoutes {
  public static account: typeof AccountRoutes = AccountRoutes;
  public static choir: typeof ChoirRoutes = ChoirRoutes;
  public static dashboard: typeof DashboardRoutes = DashboardRoutes;
  public static events: typeof EventRoutes = EventRoutes;
  public static auth: typeof AuthRoutes = AuthRoutes;
  public static news: typeof NewsRoutes = NewsRoutes;
  public static videoLessons: typeof VideoLessonsRoutes = VideoLessonsRoutes;
  public static audioBooks: typeof AudioBookRoutes = AudioBookRoutes;
  public static messaging: typeof MessagingRoutes = MessagingRoutes;
  public static signUps: typeof SignUpRoutes = SignUpRoutes;

  public static supporters = `${apiPrefix}/supporters/`;
  public static testimonials = `${apiPrefix}/testimonials/`;
  public static choirs = `${apiPrefix}/choirs/`;
  public static objectives = `${apiPrefix}/objectives/`;
  public static announcements = `${apiPrefix}/announcements/`;
  public static objectiveNumbers = `${apiPrefix}/objective-numbers/`;
  public static questionnaire = `${signupPrefix}/questionnaire/`;
  public static download(type: string, id: number): string {
    return `${apiPrefix}/download/${type}/${id}/`;
  }
}

export function ApiRoutesPlugin(Vue: typeof _Vue): void {
  Vue.prototype.$apiRoutes = ApiRoutes;
}
