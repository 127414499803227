
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FullWidthTitle extends Vue {
  @Prop() public title!: string;
  @Prop() public description!: string;
  @Prop() public extensions!: string;
}
