
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { VideoGalleryItem } from '../../models/VideoGallery';

@Component
export default class VideoGalleryItemComp extends Vue {
  @Prop() public data?: VideoGalleryItem;

  public videoClick() {
    if (
      !!this.data &&
      this.data.hasOwnProperty('videoId') &&
      this.data.videoId
    ) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          watchVideo: this.data.videoId.toString(),
        },
      });
    }
  }
}
