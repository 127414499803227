



















import { Component, Vue } from 'vue-property-decorator';
import Hero from '../../components/home/Hero.vue';
import Program from '../../components/home/Program.vue';
import CalltoAction from '../../components/shared/CallToAction.vue';
import CelebrityTestimonials from '../../components/home/CelebrityTestimonials.vue';
import Founder from '../../components/home/Founder.vue';
import Mission from '../../components/home/Mission.vue';
import Network from '../../components/home/network/Network.vue';
import News from '../../components/home/News.vue';
import Objectives from '../../components/home/Objectives.vue';
import PhotoGallery from '../../components/home/PhotoGallery.vue';
import Testimonials from '../../components/home/Testimonials.vue';
import VideoGallery from '../../components/home/VideoGallery.vue';
import PreselectionsCallToAction from '../../components/home/PreselectionsCallToAction.vue';
const Numbers = () => import('../../components/home/Numbers.vue');

@Component({
  components: {
    Program,
    Hero,
    CalltoAction,
    CelebrityTestimonials,
    Founder,
    Mission,
    Network,
    News,
    Numbers,
    Objectives,
    PhotoGallery,
    Testimonials,
    VideoGallery,
    PreselectionsCallToAction,
  },
})
export default class Home extends Vue {}
