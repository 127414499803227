import { render, staticRenderFns } from "./ContactMadrigalist.vue?vue&type=template&id=aef42e62&scoped=true&"
import script from "./ContactMadrigalist.vue?vue&type=script&lang=ts&"
export * from "./ContactMadrigalist.vue?vue&type=script&lang=ts&"
import style0 from "./ContactMadrigalist.vue?vue&type=style&index=0&id=aef42e62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef42e62",
  null
  
)

export default component.exports