import Vue from 'vue';
import { Validation } from 'vuelidate';
import { Component, Watch } from 'vue-property-decorator';
import { ApiError } from '../models/errors';
import { Getter } from 'vuex-class';
import * as userGetters from '../modules/user/getters';
import { Choir } from '../models/Choir';

@Component
export class RedirectMixin extends Vue {
  @Getter(userGetters.USER_CHOIRS) public rdrUserChoirs: Choir[];
  @Getter(userGetters.SELECTED_CHOIR) public rdrSelectedChoir: Choir;

  @Watch('rdrUserChoirs') public rdrOnUserChoirsUpdate(n: any) {
    if (n && Array.isArray(n) && n.length === 0) {
      this.$router.replace({ name: 'dashboard' });
    }
  }

  @Watch('rdrSelectedChoir') public rdrOnSelectedChoirUpdate(n: Choir) {
    this.rdrCheck();
  }

  @Watch('$route', { immediate: true }) public rdrOnRouteUpdate() {
    this.rdrCheck();
  }

  private rdrCheck() {
    if (
      this.rdrSelectedChoir &&
      !(
        this.rdrSelectedChoir.is_confirmed &&
        this.rdrSelectedChoir.user_is_confirmed
      )
    ) {
      this.$router.replace({ name: 'dashboard' });
    }
  }
}
