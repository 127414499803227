











import { Component, Vue } from 'vue-property-decorator';
import TestimonialTemplate from './TestimonialTemplate.vue';
import { Testimonial } from '../../models/Testimonial';
import { TestimonialTranslation } from '../../models/TestimonialTranslation';

@Component({
  components: { TestimonialTemplate },
})
export default class Testimonials extends Vue {
  private testimonials: Testimonial[] = [];

  public mounted() {
    this.$http.get(this.$apiRoutes.testimonials).then((response) => {
      response.data.forEach((item: any) => {
        const trans: TestimonialTranslation[] = [];
        item.translations.forEach((t: any) => {
          trans.push(new TestimonialTranslation(t.language, t.text));
        });
        this.testimonials.push(
          new Testimonial({
            name: item.name,
            order: item.order,
            translations: trans,
          }),
        );
      });
    });
  }
}
