



































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { required, email } from 'vuelidate/lib/validators';
import { Credentials } from '../../../models/Auth';
import { FormError } from '../../../modules/utils';
import { FieldStateMixin } from '../../../utils/FieldStateMixin';
import InvalidForm from '../../../components/shared/InvalidForm.vue';

@Component({
  components: { InvalidForm },
  mixins: [FieldStateMixin],
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
})
export default class Login extends Mixins(FieldStateMixin) {
  public form: Credentials = {
    email: '',
    password: '',
  };
  @Action('login') public login: (data: Credentials) => void;
  @Getter('authError') public authError: FormError | null;
  public get fromActivation(): boolean {
    return this.$route.query.hasOwnProperty('activated');
  }

  private activatedUser: boolean = false;

  public mounted() {
    if (this.$route.query.hasOwnProperty('activated')) {
      this.activatedUser = true;
    }
  }

  public performLogin(event: Event) {
    event.preventDefault();
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.login(this.form);
    }
  }
}
