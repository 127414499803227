














import { Component, Prop, Vue } from 'vue-property-decorator';
import EventDetails from '../account/events/EventDetails.vue';

@Component({
  components: {
    EventDetails,
  },
})
export default class EventDetailsModal extends Vue {
  @Prop() private msg!: string;
}
