


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { News } from '../../../models/News';

@Component
export default class NewsItemTemplate extends Vue {
  @Prop() public data?: News;

  public get category(): string {
    return this.data.getCategoryDisplay();
  }
}
