





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Video } from '../../models/News';

@Component
export default class NewsContest extends Vue {
  @Prop() private data!: Video;
}
