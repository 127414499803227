




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ListItem extends Vue {
  @Prop() public topText!: string;
  @Prop() public bottomText!: string;
  @Prop() public difficulty!: string;
  @Prop() public hasBorder!: boolean;
}
