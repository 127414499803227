var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"top-image"})],1),_c('section-header',{attrs:{"title":'Parteneri',"description":'Cantus Mundi își propune ca prin muzică să depășească toate barierele sociale sau de altă ' +
      'natură, formând o comunitate culturală, accesibilă tuturor copiilor și tinerilor. ' +
      'Din 2011, am pornit un amplu demers social - cultural prin care aducem împreună pe marile ' +
      'scene din România zeci de mii de copii și tineri din comunitatea Cantus Mundi. Visele lor se ' +
      'transformă în realitate în cadrul unor evenimente Cantus Mundi reprezentative: festivaluri, ' +
      'turnee, concerte, gale corale, spectacole, producții video, școli de vară, tabere și ateliere de ' +
      'formare. Anual, au loc sute de asemenea întâlniri muzicale extinse în toată țara, organizate de ' +
      'echipa Cantus Mundi, membri sau prieteni ai comunității Cantus Mundi. ' +
      'În 2019, peste 50.000 de copii au urcat pe scenele Cantus Mundi, susținuți și aplaudați de peste ' +
      '170.000 de spectatori.'}}),_c('full-width-title',{attrs:{"title":'Instituții'}}),_c('b-container',[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{staticClass:"mb-4 mb-lg-0",attrs:{"lg":"4"}},[_c('div',{staticClass:"box",attrs:{"id":"madrigal"}})]),_c('b-col',{staticClass:"mb-4 mb-lg-0",attrs:{"lg":"4"}},[_c('div',{staticClass:"box",attrs:{"id":"occ"}})]),_c('b-col',{staticClass:"mb-4 mb-lg-0",attrs:{"lg":"4"}},[_c('a',{attrs:{"href":"https://www.loto.ro/","target":"_blank"}},[_c('div',{staticClass:"box",attrs:{"id":"lr"}})])])],1)],1),_c('section-header',{attrs:{"title":"Vreau să devin partener","description":'Sprijină integrarea prin muzică a copiilor din România! ' +
      'Dacă dorești să fii un membru activ al societății și să te implici într-un proiect socio-cultural de ' +
      'anvergură națională și internațională dedicat copiilor, ai ajuns unde trebuie! ' +
      'Fii partenerul nostru! Completează formularul și echipa Cantus Mundi te va contacta în scurt ' +
      'timp!'}}),_c('b-container',[_c('b-row',{staticClass:"justify-content-center button-row"},[_c('b-col',{attrs:{"cols":"12","sm":"auto"}},[_c('b-button',{staticClass:"text-uppercase",attrs:{"size":"lg","variant":"danger"},on:{"click":function($event){return _vm.$root.$emit('openContact')}}},[_c('strong',[_vm._v("Contact")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }