















































































import { Component, Emit, Mixins, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { User } from '../../models/Auth';
import { FieldStateMixin } from '../../utils/FieldStateMixin';
import { required } from 'vuelidate/lib/validators';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import InvalidForm from '../shared/InvalidForm.vue';
import { LoadingState } from '../../modules/utils';
import { County } from '../../modules/definitions/types';
import moment from 'moment';

@Component({
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      birth_date: { required },
      localitate: { required },
      judet: { required },
      phone: {},
    },
  },
  components: { InvalidForm },
})
export default class EditAccount extends Mixins(FieldStateMixin) {
  @Getter('userDetails') public userDetails: User;
  @Getter('counties') public counties: LoadingState<County[]>;
  @Action('userDetails') public loadUserDetails: () => void;
  public $refs!: Vue['$refs'] & {
    file: {
      files: any;
    };
  };
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation } & EditAccountRequest;
    };

  public form: EditAccountRequest = {
    first_name: null,
    last_name: null,
    birth_date: null,
    localitate: null,
    judet: null,
    phone: null,
  };

  @Watch('userDetails', { immediate: true }) public onUserDetailsUpdate() {
    this.form.first_name = this.userDetails.firstName;
    this.form.last_name = this.userDetails.lastName;
    this.form.birth_date = this.userDetails.birthDate;
    this.form.localitate = this.userDetails.city;
    this.form.judet = this.userDetails.county;
    this.form.phone = this.userDetails.phone;
  }

  public uploadFile() {
    if (!this.userDetails) {
      return;
    }
    const formData = new FormData();
    formData.append('image', this.$refs.file.files[0]);
    this.$http
      .post(this.$apiRoutes.account.avatar, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        this.loadUserDetails();
      })
      .catch(() => {});
  }

  public save() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$http
        .post(this.$apiRoutes.account.edit, {
          ...this.form,
          birth_date: moment(this.form.birth_date).format('YYYY-MM-DD'),
        })
        .then(() => {
          this.$bvModal.hide('edit-account');
          this.loadUserDetails();
        })
        .catch(() => {
          this.$bvToast.toast('A apărut o eroare la salvarea datelor', {
            variant: 'danger',
          });
        });
    }
  }
}

interface EditAccountRequest {
  first_name: string;
  last_name: string;
  birth_date: string;
  localitate: string;
  judet: string;
  phone: string;
}
