








































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import UnconfirmedChoir from '../../../components/UnconfirmedChoir.vue';
import DashboardSummaryBox from '../../../components/account/dashboard/DashboardSummaryBox.vue';
import Messages from '../../../components/account/dashboard/Messages.vue';
import PendingAccounts from '../../../components/account/dashboard/PendingAccounts.vue';
import FutureEvents from '../../../components/account/dashboard/FutureEvents.vue';
import Audiobooks from '../../../components/account/dashboard/Audiobooks.vue';
import Scores from '../../../components/account/dashboard/Scores.vue';
import DateComp from '../../../components/account/dashboard/DateComp.vue';
import ChoirSelect from '../../../components/account/ChoirSelect.vue';
import ListItem from '../../../components/account/dashboard/ListItem.vue';
import ListActionComp from '../../../components/account/dashboard/ListActionComp.vue';
import SummaryBox from '../../../components/account/SummaryBox.vue';
import { User } from '../../../models/Auth';
import { Choir } from '../../../models/Choir';
import BoxHeader from '../../../components/account/dashboard/BoxHeader.vue';
import * as userGetters from '../../../modules/user/getters';
import VideoLessons from '../../../components/account/dashboard/VideoLessons.vue';

@Component({
  components: {
    VideoLessons,
    UnconfirmedChoir,
    DashboardSummaryBox,
    Audiobooks,
    Scores,
    FutureEvents,
    PendingAccounts,
    Messages,
    DateComp,
    ChoirSelect,
    ListItem,
    BoxHeader,
    ListActionComp,
    SummaryBox,
  },
})
export default class Dashboard extends Vue {
  @Getter(userGetters.USER_DETAILS) public userDetails: User;
  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;
  @Getter(userGetters.USER_CHOIRS) public userChoirs: Choir[];
  @Getter(userGetters.IS_CONDUCTOR) public isConductor: boolean;
}
