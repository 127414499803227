






































import { Component, Prop, Vue } from 'vue-property-decorator';
import FullWidthTitle from '../shared/FullWidthTitle.vue';
import moment, { Moment } from 'moment';
import { Announcement as AnnouncementModel } from '../../models/announcements';

@Component({
  components: { FullWidthTitle },
})
export default class Announcement extends Vue {
  @Prop({ required: true }) public data: AnnouncementModel;

  public get date(): Moment {
    return moment(this.data.date).locale('ro');
  }

  public get day() {
    return this.date.format('D');
  }

  public get month() {
    return this.date.format('MMM');
  }
}
