import { MutationTree } from 'vuex';
import { UserState } from './types';
import { Token, User } from '../../models/Auth';
import { FormError } from '../utils';
import { Choir } from '../../models/Choir';

export const AUTHENTICATING = 'authenticating';
export const LOGIN_DONE = 'loginDone';
export const LOGIN_ERROR = 'loginError';
export const USER_DETAILS = 'userDetails';
export const USER_DETAILS_ERROR = 'userDetailsError';
export const LOADING_USER_DETAILS = 'loadingUserDetails';
export const LOGOUT = 'logout';
export const LOAD_CHOIRS = 'loadChoirs';
export const LOAD_CHOIR = 'loadChoir';
export const CHOIR_SELECT = 'choirSelect';

export const mutations: MutationTree<UserState> = {
  [AUTHENTICATING](s: UserState) {
    s.authenticating = true;
  },
  [LOGIN_DONE](s: UserState, payload: Token) {
    s.authenticating = false;
    s.error = null;
    s.token = payload;
  },
  [LOGIN_ERROR](s: UserState, payload: { status: number; data: any }) {
    s.authenticating = false;
    s.error = payload.data;
    s.token = undefined;
  },
  [USER_DETAILS](s: UserState, payload: User) {
    s.userDetails = payload;
  },
  [USER_DETAILS_ERROR](s: UserState, payload: FormError) {
    s.error = payload;
  },
  [LOADING_USER_DETAILS](s: UserState) {
    s.loadingUserDetails = true;
  },
  [LOGOUT](s: UserState) {
    s.authenticating = false;
    s.token = undefined;
    s.error = null;
    s.loadingUserDetails = false;
    s.userDetails = null;
    s.selectedChoir = null;
  },
  [LOAD_CHOIRS](s: UserState, payload: Choir[]) {
    s.userChoirs = payload;
    if (payload.length > 0) {
      const confirmedChoir = payload.find(
        (choir: Choir) => choir.user_is_confirmed,
      );
      s.selectedChoir = confirmedChoir ? confirmedChoir : payload[0];
    }
  },
  [LOAD_CHOIR](s: UserState, payload: Choir) {
    let choirs: Choir[] = [];
    if (s.userChoirs) {
      choirs =
        s.userChoirs &&
        s.userChoirs.filter((choir: Choir) => choir.id !== payload.id);
    }
    choirs.push(payload);
    s.userChoirs = choirs;
    if (s.selectedChoir && s.selectedChoir.id === payload.id) {
      s.selectedChoir = payload;
    }
  },
  [CHOIR_SELECT](s: UserState, payload: Choir) {
    s.selectedChoir = payload;
  },
};
