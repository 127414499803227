



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import FullWidthTitle from '../../../components/shared/FullWidthTitle.vue';
import ContestTemplate from '../../../components/events/news/ContestTemplate.vue';
import NewsItemTemplate from '../../../components/events/news/NewsItemTemplate.vue';
import MediaItemTemplate from '../../../components/events/news/MediaItemTemplate.vue';
import {
  Category,
  FrontPageNews,
  News as NewsModel,
} from '../../../models/News';

@Component({
  components: {
    FullWidthTitle,
    ContestTemplate,
    MediaItemTemplate,
    NewsItemTemplate,
  },
})
export default class News extends Vue {
  public categories: Category[] = [new Category('all', 'Toate')];
  public data: FrontPageNews | null = null;

  public mounted() {
    this.$http.get(this.$apiRoutes.news.categories).then((response) => {
      response.data.forEach((category: any) => {
        this.categories.push(new Category(category.key, category.value));
      });
    });
  }

  public get isEmpty(): boolean {
    return (
      this.data &&
      !this.data.contest &&
      !(this.data.videos && this.data.videos.length > 0) &&
      !(this.data.news && this.data.news.length > 0)
    );
  }

  public get displayContest(): boolean {
    return (
      this.data &&
      this.data.contest &&
      ['contest', 'all'].includes(this.selectedCategory)
    );
  }

  public get hasVideos(): boolean {
    return this.data.videos && this.data.videos.length > 0;
  }

  public get videos() {
    return this.hasVideos ? this.data.videos : [];
  }

  public selectCategory(category: string) {
    this.$router.replace({
      ...this.$route,
      query: { ...this.$route.query, category },
    });
  }

  public get selectedCategory(): string {
    if (Array.isArray(this.$route.query.category)) {
      return this.$route.query.category[0];
    } else if (this.$route.query.category) {
      return this.$route.query.category;
    } else {
      return 'all';
    }
  }

  public get news(): NewsModel[] {
    if (this.hasVideos) {
      return this.data.news.slice(1, this.data.news.length);
    } else {
      return this.data.news;
    }
  }

  @Watch('selectedCategory', { immediate: true })
  public onSelectedCategoryUpdate() {
    const params: { category?: string } = {};
    if (this.selectedCategory && this.selectedCategory !== 'all') {
      params.category = this.selectedCategory;
    }
    this.data = null;
    this.$http.get(this.$apiRoutes.news.list, { params }).then((response) => {
      const data = new FrontPageNews();
      response.data.forEach((news: any) => {
        data.addRawData(news);
      });
      this.data = data;
    });
  }
}
