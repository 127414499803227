import Vue from 'vue';
import { Validation } from 'vuelidate';
import { Component } from 'vue-property-decorator';
import { ApiError } from '../models/errors';

@Component
export class FieldStateMixin extends Vue {
  public fieldState(field: Validation, serverErrors?: ApiError): boolean {
    return field.$dirty ? (serverErrors ? false : !field.$error) : null;
  }
}
