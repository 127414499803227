import { Module } from 'vuex';
import { UserState } from './types';
import { RootState } from '../../store';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: UserState = {
  error: null,
  token: undefined,
  authenticating: false,
  loadingUserDetails: false,
  userDetails: null,
  selectedChoir: null,
  userChoirs: null,
};

const namespaced: boolean = false;

export const user: Module<UserState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
