import { GetterTree } from 'vuex';
import { Media, MediaState, MediaWrapper, Video } from './types';
import { RootState } from '../../store';

export const IS_PLAYING = 'isPlaying';
export const PLAYING = 'playing';
export const HAS_MEDIA = 'hasMedia';
export const HAS_NEXT = 'hasNext';
export const HAS_PREVIOUS = 'hasPrevious';
export const SHOW_PLAYLIST = 'showPlaylist';
export const GET_LOADED_MEDIA = 'getLoadedMedia';

export const getters: GetterTree<MediaState, RootState> = {
  [IS_PLAYING](state: MediaState): boolean {
    return state.playing;
  },
  [PLAYING](state: MediaState): Media | Video | null {
    return state.playingMedia;
  },
  [HAS_MEDIA](state: MediaState): boolean {
    return !!state.playlist;
  },
  [HAS_NEXT](state: MediaState): boolean {
    const index = state.playlist.media.findIndex((media: Media | Video) => {
      return state.playingMedia && state.playingMedia.name === media.name;
    });
    return !(index >= state.playlist.media.length - 1);
  },
  [HAS_PREVIOUS](state: MediaState): boolean {
    const index = state.playlist.media.findIndex((media: Media | Video) => {
      return state.playingMedia && state.playingMedia.name === media.name;
    });
    return !(index < 1);
  },
  [SHOW_PLAYLIST](state: MediaState): boolean {
    return state.showPlaylist;
  },
  [GET_LOADED_MEDIA](state: MediaState): MediaWrapper {
    return state.playlist;
  },
};
