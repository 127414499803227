














































import { Component, Vue } from 'vue-property-decorator';
import NewsContest from '../../components/home/NewsContest.vue';
import NewsVideo from '../../components/home/NewsVideo.vue';
import NewsItem from '../../components/home/NewsItem.vue';
import Spacer from '../../components/shared/Spacer.vue';
import { FrontPageNews, News as NewsModel } from '../../models/News';

@Component({
  components: { Spacer, NewsItem, NewsVideo, NewsContest },
})
export default class News extends Vue {
  public data: FrontPageNews | null = null;
  public $mq: string;

  public mounted() {
    this.$http.get(this.$apiRoutes.news.list).then((response) => {
      const data = new FrontPageNews();
      response.data.forEach((news: any) => {
        data.addRawData(news);
      });
      this.data = data;
    });
  }

  public get news(): NewsModel[] {
    if (this.$mq === 'md') {
      return this.data.news.slice(0, 4);
    } else {
      return this.data.news.slice(0, 3);
    }
  }
}
