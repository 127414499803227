






























































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { County } from '../../../models/Map';

@Component
export default class MapController extends Vue {
  @Prop() public counties?: County[];
  @Prop() public selectedCounty: string;

  public selectedCountyName: string | null = null;
  private scrollOptions = {
    container: '#county-wrapper',
  };
  @Watch('selectedCounty') public onSelectedCountyUpdate() {
    this.openCounty(this.getCountyFromAbbrev(this.selectedCounty));
  }
  @Emit('countyExpanded') public onCountyExpanded(county: string) {
    return county;
  }

  public getCountyFromAbbrev(abbrev: string): County | null {
    if (this.counties) {
      return this.counties.filter((county) => county.abbrev === abbrev)[0];
    } else {
      return null;
    }
  }

  public expandCounty(county: County) {
    this.openCounty(county);
    this.onCountyExpanded(county.abbrev);
  }

  public openCounty(county: County) {
    this.selectedCountyName = county.name;
    const id = `#${this.getCountyId(county)}`;
    this.$scrollTo(id, 500, this.scrollOptions);
  }

  public closeCounty() {
    this.onCountyExpanded(null);
  }

  public getCountyId(county: County) {
    return `county-${county.abbrev}`;
  }

  public hasSelectedCounty(): boolean {
    return this.selectedCountyName !== null;
  }

  public getSelectedCountyName(): string {
    if (this.selectedCountyName) {
      return this.selectedCountyName;
    } else {
      return '';
    }
  }
}
