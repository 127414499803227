














import { Component, Vue } from 'vue-property-decorator';
import Scores from '../../../components/account/dashboard/Scores.vue';
import Audiobooks from '../../../components/account/dashboard/Audiobooks.vue';
import VideoLessons from '../dashboard/VideoLessons.vue';

@Component({
  components: { VideoLessons, Audiobooks, Scores },
})
export default class LibrarySummary extends Vue {}
