






































































































import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { ScoresFilters } from '../../../models/Scores';
import { Filters } from '../../../models/Filters';

@Component({})
export default class FiltersBox extends Vue {
  public get collapseVisible(): boolean {
    return this.$mq !== 'xs';
  }
  @Model() public filter: ScoresFilters;

  @Prop() public filters: Filters;
  @Prop({ default: true }) public loading: boolean;

  public $mq: string;

  @Emit() public resetFilters() {}
}
