













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TeamMember extends Vue {
  @Prop() public name!: string;
  @Prop() public position!: string;
  @Prop() public description!: string;
  @Prop() public image!: string;
}
