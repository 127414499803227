var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',[_c('full-width-title',{attrs:{"title":"DA pentru ora de muzică!"}})],1)],1),_c('b-row',[_c('b-col',[_c('p',[_c('strong',[_vm._v("Programul Național Cantus Mundi")]),_vm._v(", prin activitatea sa de aproape un deceniu în domeniul educației muzicale și a incluziunii sociale prin muzică, este agregatorul unei comunități de peste 60.000 de elevi și profesori din România. Prin acest chestionar dorim să investigăm opinia publică asupra necesității păstrării Educației Muzicale ca disciplină de studiu, în contextul în care în această perioadă Ministerul Educației și Cercetării discută variante de "),_c('strong',[_vm._v("plan-cadru de învățământ în care orele de muzică NU mai există")]),_vm._v(". ")]),_c('p',[_vm._v(" Prin completarea acestui chestionar ne ajutați să creionăm o imagine cât mai clară asupra necesităților reale ale învățământului românesc, referitoare la educația muzicală. ")]),_c('b-card',[_c('b-card-body',[_c('h5',[_vm._v("Vă rugăm să completați următoarele date personale:")]),_c('b-alert',{attrs:{"variant":"danger","show":_vm.serverError}},[_c('strong',[_vm._v("Formularul nu a putut fi salvat!")]),_c('br'),_vm._v(" Te rugăm să încerci mai târziu.")]),_c('b-alert',{attrs:{"variant":"success","show":_vm.success}},[_c('strong',[_vm._v("Îți mulțumim!")]),_vm._v(" Răspunsurile tale au fost salvate cu succes.")]),_c('b-form',[_c('b-form-group',{attrs:{"label":"Nume și prenume","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.name)},model:{value:(_vm.$v.form.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.name, "$model", $$v)},expression:"$v.form.name.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.name}})],1),_c('b-form-group',{attrs:{"label":"Județ","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-select',{attrs:{"options":_vm.counties.data,"state":_vm.fieldState(_vm.$v.form.county)},model:{value:(_vm.$v.form.county.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.county, "$model", $$v)},expression:"$v.form.county.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.county}})],1),_c('b-form-group',{attrs:{"label":"Zona în care locuiți","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.areaOptions,"state":_vm.fieldState(_vm.$v.form.area)},model:{value:(_vm.$v.form.area.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.area, "$model", $$v)},expression:"$v.form.area.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.area}})],1),_c('b-form-group',{attrs:{"label":"Care este ultima școală absolvită de dumneavoastră","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.educationOptions,"state":_vm.fieldState(_vm.$v.form.education)},model:{value:(_vm.$v.form.education.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.education, "$model", $$v)},expression:"$v.form.education.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.education}})],1),_c('b-form-group',{attrs:{"label":"Lucrați în învățământ în calitate de cadru didactic?","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions,"state":_vm.fieldState(_vm.$v.form.workplace)},model:{value:(_vm.$v.form.workplace.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.workplace, "$model", $$v)},expression:"$v.form.workplace.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.workplace}})],1),_c('b-form-group',{attrs:{"label":"Sunteți implicat în activitatea corală (inclusiv situația în care copiii dvs. urmează astfel de activități)?","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions,"state":_vm.fieldState(_vm.$v.form.involved)},model:{value:(_vm.$v.form.involved.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.involved, "$model", $$v)},expression:"$v.form.involved.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.involved}})],1),_c('b-form-group',{attrs:{"label":"Considerați că disciplina Educație Muzicală predată la școală este relevantă în educația formală a copiilor/ adolescenților?","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions,"state":_vm.fieldState(_vm.$v.form.q2)},model:{value:(_vm.$v.form.q2.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.q2, "$model", $$v)},expression:"$v.form.q2.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.q2}})],1),_c('b-form-group',{attrs:{"label":"Considerați că eventuala eliminare a orei de Educație Muzicală îi va ajuta pe elevi să aibă o programă mai puțin încărcată?","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions,"state":_vm.fieldState(_vm.$v.form.q3)},model:{value:(_vm.$v.form.q3.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.q3, "$model", $$v)},expression:"$v.form.q3.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.q3}})],1),_c('b-form-group',{attrs:{"label":"Sunteți de părere că activitatea muzicală practică îi ajută pe elevi să își dezvolte abilitățile de comunicare și integrare socială?","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions,"state":_vm.fieldState(_vm.$v.form.q4)},model:{value:(_vm.$v.form.q4.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.q4, "$model", $$v)},expression:"$v.form.q4.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.q4}})],1),_c('b-form-group',{attrs:{"label":"Considerați că ora de Educație Muzicală ar trebui să aibă o componentă majoritară practică?","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions,"state":_vm.fieldState(_vm.$v.form.q5)},model:{value:(_vm.$v.form.q5.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.q5, "$model", $$v)},expression:"$v.form.q5.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.q5}})],1),_c('b-form-group',{attrs:{"label":"Considerați suficientă o oră pe săptămână pentru disciplina Educație muzicală la ciclul gimnazial?","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNoOptions,"state":_vm.fieldState(_vm.$v.form.q6)},model:{value:(_vm.$v.form.q6.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.q6, "$model", $$v)},expression:"$v.form.q6.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.q6}})],1),_c('b-form-group',{attrs:{"label":"Menționați poziția dvs. referitoare la păstrarea Educației Muzicale ca disciplină de studiu obligatorie în planul cadru de învățământ.","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-form-radio-group',{attrs:{"options":_vm.proAgainstOptions,"state":_vm.fieldState(_vm.$v.form.q7)},model:{value:(_vm.$v.form.q7.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.q7, "$model", $$v)},expression:"$v.form.q7.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.q7}})],1),_c('b-form-group',{attrs:{"label":"Adresa de e-mail, dacă doriți să vă abonați la Newsletter-ul nostru","label-cols-md":"4","label-class":{
                'text-right': ['md', 'lg', 'xl'].includes(_vm.$mq),
                'font-weight-bold': true,
              }}},[_c('b-input',{attrs:{"state":_vm.fieldState(_vm.$v.form.email)},model:{value:(_vm.$v.form.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.email, "$model", $$v)},expression:"$v.form.email.$model"}}),_c('invalid-form',{attrs:{"field":_vm.$v.form.email}})],1),_c('b-form-group',[_c('p',[_vm._v(" Datele dumneavoastră personale din acest formular sunt protejate conform legislației în vigoare și nu vor fi folosite în scopuri comerciale. Prelucrarea acestora va fi efectuată pentru realizarea studiului privind importanța Educației Muzicale în școli. ")]),_c('p',[_vm._v(" Prin completarea și transmiterea acestui formular vă oferiți acordul ca datele dumneavoastră să fie prelucrate în scopurile precizate mai sus. ")]),_c('b-form-checkbox',{attrs:{"state":_vm.fieldState(_vm.$v.form.agreed),"checked":_vm.$v.form.agreed,"disabled":_vm.busy},model:{value:(_vm.$v.form.agreed.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.agreed, "$model", $$v)},expression:"$v.form.agreed.$model"}},[_c('strong',[_vm._v("Sunt de acord")]),_c('invalid-form',{attrs:{"field":_vm.$v.form.agreed}})],1)],1),_c('b-form-group',{staticClass:"text-center mt-3"},[_c('b-button',{staticClass:"text-uppercase align-self-center",attrs:{"variant":"danger"},on:{"click":_vm.submit}},[_vm._v("Trimite")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }