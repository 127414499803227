







































import { Component, Vue, Watch } from 'vue-property-decorator';
import ListActionComp from './ListActionComp.vue';
import DashboardBoxHeader from './BoxHeader.vue';
import { Getter } from 'vuex-class';
import DateComp from './DateComp.vue';
import ListItem from './ListItem.vue';
import { User } from '../../../models/Auth';
import { FutureEvent } from '../../../models/Dashboard';
import { Choir } from '../../../models/Choir';

@Component({
  components: { DateComp, ListItem, DashboardBoxHeader, ListActionComp },
})
export default class FutureEvents extends Vue {
  @Getter('userDetails') public userDetails: User;
  @Getter('selectedChoir') public selectedChoir: Choir;

  public futureEvents: FutureEvent[] = [];

  public loading: boolean;

  @Watch('selectedChoir', { immediate: true })
  public onSelectedChoirUpdate() {
    this.futureEvents = [];
    this.loading = true;
    if (this.selectedChoir.id === undefined) {
      return;
    }
    this.$http
      .get(this.$apiRoutes.dashboard.events(this.selectedChoir.id))
      .then((response: any) => {
        this.futureEvents = response.data;
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }
}
