
















































































































































































































































































import { Component, Mixins, Ref, Vue } from 'vue-property-decorator';
import FullWidthTitle from '../shared/FullWidthTitle.vue';
import { email, required } from 'vuelidate/lib/validators';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import FormAgreement from './FormAgreement.vue';
import { FieldStateMixin } from '../../utils/FieldStateMixin';
import InvalidForm from '../shared/InvalidForm.vue';
import { selected } from '../../utils/validators';
import { Getter } from 'vuex-class';
import { LoadingState } from '../../modules/utils';
import { County } from '../../modules/definitions/types';
@Component({
  components: { FullWidthTitle, FormAgreement, InvalidForm },
  validations: {
    form: {
      name: { required },
      county: { required },
      area: { required },
      education: { required },
      workplace: { required },
      involved: { required },
      q2: { required },
      q3: { required },
      q4: { required },
      q5: { required },
      q6: { required },
      q7: { required },
      email: { email },
      agreed: { required, selected },
    },
  },
})
export default class CampaignForm extends Mixins(FieldStateMixin) {
  @Getter('counties') public counties: LoadingState<County[]>;
  public form: CampaignFormModel = this.getEmptyForm();
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation };
    };
  public serverError: boolean = false;
  public success: boolean = false;
  public busy: boolean = false;

  public areaOptions = [
    {
      text: 'Rural',
      value: 'rural',
    },
    {
      text: 'Urban',
      value: 'urban',
    },
  ];

  public educationOptions = [
    {
      text: 'Școala generală',
      value: 'elementary',
    },
    {
      text: 'Liceu',
      value: 'high-school',
    },
    {
      text: 'Învățământ superior - licență',
      value: 'bachelor',
    },
    {
      text: 'Program master',
      value: 'masters',
    },
    {
      text: 'Program doctorat',
      value: 'phd',
    },
  ];

  public yesNoOptions = [
    {
      text: 'Da',
      value: 'yes',
    },
    {
      text: 'Nu',
      value: 'no',
    },
  ];

  public proAgainstOptions = [
    {
      text: 'Pro',
      value: 'pro',
    },
    {
      text: 'Contra',
      value: 'against',
    },
  ];

  public submit() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.busy = true;
      this.serverError = false;
      this.success = false;
      this.$http
        .post(this.$apiRoutes.signUps.campaign, this.form)
        .then(() => {
          this.success = true;
          this.form = this.getEmptyForm();
        })
        .catch(() => (this.serverError = true))
        .finally(() => {
          this.$scrollTo('#app', 1000, {
            container: 'body',
            easing: 'ease-in-out',
            force: true,
            cancelable: false,
          });
          this.$v.$reset();
          this.busy = false;
        });
    }
  }

  private getEmptyForm(): CampaignFormModel {
    return {
      name: null,
      county: null,
      area: null,
      education: null,
      workplace: null,
      involved: null,
      q2: null,
      q3: null,
      q4: null,
      q5: null,
      q6: null,
      q7: null,
      email: null,
      agreed: null,
    };
  }
}

interface CampaignFormModel {
  name: string;
  county: string;
  area: string;
  education: string;
  workplace: boolean;
  involved: boolean;
  q2: boolean;
  q3: boolean;
  q4: boolean;
  q5: boolean;
  q6: boolean;
  q7: boolean;
  email: string;
  agreed: boolean;
}
