






































































































































































































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { FieldStateMixin } from '../../utils/FieldStateMixin';
import { email, required } from 'vuelidate/lib/validators';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import InvalidForm from '../../components/shared/InvalidForm.vue';
import { selected } from '../../utils/validators';
import moment from 'moment';

@Component({
  components: { InvalidForm },
  validations: {
    form: {
      name: { required },
      phone: { required },
      email: { required, email },
      childName: { required },
      choirName: { required },
      agree1: { selected },
      agree2: { selected },
      agree3: { selected },
      agree4: { selected },
      agree5: { selected },
      agree6: { selected },
      agree7: { selected },
      agree8: { selected },
      agree9: { selected },
      agree10: { selected },
      date: { required },
    },
  },
})
export default class PersonalDataAgreement extends Mixins(FieldStateMixin) {
  public saving: boolean = false;
  public saved: boolean = false;
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation } & FormData;
    };
  public $refs: Vue['$refs'] & { signature: Signature; form: HTMLElement };
  public form: FormData = {
    name: null,
    phone: null,
    email: null,
    childName: null,
    choirName: null,
    agree1: null,
    agree2: null,
    agree3: null,
    agree4: null,
    agree5: null,
    agree6: null,
    agree7: null,
    agree8: null,
    agree9: null,
    agree10: null,
    date: null,
    signature: null,
  };
  public invalidSignature: boolean = false;

  public get signature(): Signature {
    return this.$refs.signature;
  }

  public submit() {
    this.$v.$touch();
    if (this.signature.isEmpty()) {
      this.invalidSignature = true;
    }
    if (this.$v.$invalid || this.signature.isEmpty()) {
      this.$bvToast.toast('Te rugăm rezolvă erorile din formular', {
        title: 'Formular incomplet',
        variant: 'danger',
      });
      this.$scrollTo('.invalid-feedback');
      return;
    }
    this.signature.lockSignaturePad();
    this.saving = true;
    const pdfMake = require('pdfmake/build/pdfmake.js');
    const pdfFonts = require('pdfmake/build/vfs_fonts.js');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake
      .createPdf(this.pdfData)
      .download('acord-prelucrarea-datelor.pdf', this.onFinished);
  }

  public onFinished() {
    this.saving = false;
    this.signature.openSignaturePad();
    this.signature.clearSignature();
    this.form = {
      name: null,
      phone: null,
      email: null,
      childName: null,
      choirName: null,
      agree1: null,
      agree2: null,
      agree3: null,
      agree4: null,
      agree5: null,
      agree6: null,
      agree7: null,
      agree8: null,
      agree9: null,
      agree10: null,
      date: null,
      signature: null,
    };
    this.saved = true;

    setTimeout(() => {
      this.$v.$reset();
      this.$scrollTo('#title', 500, { easing: 'easeInOutCubic' });
    }, 30);
  }

  public clearSignature() {
    this.signature.clearSignature();
    this.invalidSignature = false;
  }

  public get pdfData() {
    const image = this.signature.saveSignature('image/png');
    return {
      content: [
        {
          text: 'Acord prelucrare date cu caracter personal',
          style: 'header',
        },
        {
          style: 'normal',
          text: [
            'Subsemnatul/Subsemnata, ',
            { text: this.form.name, style: 'bold' },
            ', număr/numere de telefon de contact ',
            { text: this.form.phone, style: 'bold' },
            ', adresă de e-mail ',
            { text: this.form.email, style: 'bold' },
            ' în calitate de părinte/ reprezentant legal al minorei/ minorului ',
            { text: this.form.childName, style: 'bold' },
            ', membru al corului ',
            { text: this.form.choirName, style: 'bold' },
            '\nDeclar următoarele:\n',
          ],
        },
        {
          ul: [
            'sunt de acord ca minorul să participe, în calitate de artist liric, în cadrul repetiţiilor/spectacolelor organizate de către Corul National de Cameră Madrigal - Marin Constantin (C.N.C.M.-M.C.), sub îndrumarea permanentă a reprezentantului desemnat în acest sens de către conducerea instituţiei;\n',
            'mă oblig să îl însoţesc în permanenţă pe minor/ă la fiecare dintre aceste activităţi programate de către C.N.C.M.-M.C. sau să permit însoţirea acestuia de către dirijor sau un alt reprezentant al grupului vocal;\n',
            'am luat cunoştinţă de faptul că activităţile ce urmează a fi desfăşurate nu sunt periculoase pentru minor;\n',
            'sunt de acord cu cesiunea drepturilor conexe dreptului de autor asupra materialelor realizate pe orice tip de suport, cuprinzând prestaţia artistică a minorului/minorei, în beneficiul C.N.C.M.-M.C.;\n',
            'sunt de acord cu preluarea imaginii minorului - foto/video - în timpul desfăşurării activităţilor artistice organizate de către C.N.C.M.-M.C., respectiv cu utilizarea și prelucrarea (neremunerată) a imaginilor preluate în interesul realizării obiectivelor şi atribuţiilor statutare ale instituției, stabilite prin regulamentul de organizare și funcționare și în acord cu politica de prelucrare a datelor personale. Am fost informat/ă că utilizarea imaginilor fotografice/video în alte scopuri decât cele descrise mai sus sau pentru comercializarea prin transferul imaginilor către alţi terţi decât cei menţionaţi este strict interzisă;\n',
            'sunt de acord cu prelucrarea datelor mele și ale minorului pentru scopuri de evidență, raportări către autoritățile de resort, cu respectarea legislației în vigoare;\n',
            'sunt de acord ca datele mele de contact să fie utlizate pentru a comunica în legătură cu activitățile/ evenimentele care urmează să fie desfășurate, incluzând comunicări de marketing privind alte activități/ evenimente viitoare ale C.N.C.M.-M.C.;\n',
            'cunosc faptul că datele mele și ale minorului sunt protejate de către legislația europeană și națională cu privire la protecția datelor personale şi că am dreptul să intervin asupra lor oricând doresc şi să solicit, printr-o cerere scrisă și adresată C.N.C.M.-M.C. (model disponibil pe site-ul www.cantusmundi.com/politica-de-confidentialitate), următoarele:\n',
          ],
          style: 'marginLeft1',
        },
        {
          ol: [
            'informarea şi consultarea datelor personale prolucrate;\n',
            'actualizarea/ modificarea datelor personale prolucrate;\n',
            'ştergerea datelor personale prolucrate;\n',
            'restricţionarea şi opunerea în prelucrarea datelor personale prelucrate.\n',
          ],
          style: 'marginLeft2',
        },
        {
          ul: [
            'declar că am luat la cunoștință prevederile politicii de confidențialitate;\n',
            'declar că prezentul acord (consimţământ) este valabil pentru o perioadă de 7 ani sau până la retragerea sa.\n',
          ],
          style: 'marginLeft1',
        },
        {
          columns: [
            {
              width: '*',
              text: ['Data semnării prezentului acord:'],
            },
            {
              text: 'Părinte/Reprezentant legal,',
              width: '300',
            },
          ],
          style: 'footer',
        },
        {
          columns: [
            {
              width: '*',
              text: [moment(this.form.date).format('YYYY-MM-DD')],
              style: 'footer',
            },
            {
              image: image.data,
              width: '300',
            },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 50],
        },
        footer: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
          margin: [0, 30, 0, 0],
        },
        right: {
          alignment: 'right',
        },
        normal: {
          fontSize: 12,
        },
        bold: {
          fontSize: 12,
          bold: true,
        },
        marginLeft1: {
          margin: [25, 0, 0, 0],
        },
        marginLeft2: {
          margin: [75, 0, 0, 0],
        },
      },
    };
  }
}

interface FormData {
  name: string;
  phone: string;
  email: string;
  childName: string;
  choirName: string;
  agree1: boolean;
  agree2: boolean;
  agree3: boolean;
  agree4: boolean;
  agree5: boolean;
  agree6: boolean;
  agree7: boolean;
  agree8: boolean;
  agree9: boolean;
  agree10: boolean;
  date: Date;
  signature: string;
}

interface Signature {
  isEmpty: () => boolean;
  lockSignaturePad: () => void;
  openSignaturePad: () => void;
  clearSignature: () => void;
  saveSignature: (type?: string) => { data: any };
}
