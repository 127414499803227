

















































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FullWidthText extends Vue {
  @Prop() private title!: string;
  @Prop() private description!: string;
  @Prop() private mission!: string;
  @Prop() private button!: string;
  @Prop() private image!: string;
  @Prop() private imagePosition!: string;

  @Emit() public action() {}
}
