

















import { Component, Prop, Vue } from 'vue-property-decorator';
import EventSignUp from '../../components/account/events/EventSignUp.vue';

@Component({
  components: {
    EventSignUp,
  },
})
export default class EventSignupModal extends Vue {
  @Prop() private msg!: string;
}
