import { MutationTree } from 'vuex';
import { Media, MediaState, MediaWrapper, Video } from './types';

export const LOAD_MEDIA = 'loadMedia';
export const TOGGLE = 'toggle';
export const PLAY = 'play';
export const PAUSE = 'pause';
export const NEXT = 'next';
export const PREVIOUS = 'previous';
export const TOGGLE_PLAYLIST = 'togglePlaylist';
export const CLOSE_PLAYLIST = 'closePlaylist';
export const REMOVE_MEDIA = 'removeMedia';
export const PLAY_MEDIA = 'playMedia';

export const mutations: MutationTree<MediaState> = {
  [LOAD_MEDIA](state: MediaState, payload: MediaWrapper) {
    state.playlist = payload;
    if (payload.media.length > 0) {
      state.playingMedia = payload.media[0];
      state.playing = true;
    }
  },
  [TOGGLE](state: MediaState) {
    state.playing = !state.playing;
  },
  [PLAY](state: MediaState) {
    state.playing = true;
  },
  [PAUSE](state: MediaState) {
    state.playing = false;
  },
  [NEXT](state: MediaState) {
    if (state.playingMedia !== null) {
      const index = state.playlist.media.findIndex(
        (media: Media | Video) =>
          state.playingMedia && state.playingMedia.name === media.name,
      );
      state.playingMedia =
        state.playlist.media[(index + 1) % state.playlist.media.length];
    }
  },
  [PREVIOUS](state: MediaState) {
    if (state.playingMedia !== null) {
      const index = state.playlist.media.findIndex(
        (media: Media | Video) =>
          state.playingMedia && state.playingMedia.name === media.name,
      );
      let nextIndex = index - 1;
      if (nextIndex < 0) {
        nextIndex = state.playlist.media.length - 1;
      }
      state.playingMedia = state.playlist.media[nextIndex];
    }
  },
  [TOGGLE_PLAYLIST](state: MediaState) {
    state.showPlaylist = !state.showPlaylist;
  },
  [CLOSE_PLAYLIST](state: MediaState) {
    state.showPlaylist = false;
  },
  [REMOVE_MEDIA](state: MediaState) {
    state.playingMedia = null;
    state.playlist = null;
    state.playing = false;
  },
  [PLAY_MEDIA](state: MediaState, media: Media) {
    const mediaToPlay = state.playlist.media.find(
      (playlistMedia: Media | Video) => {
        return playlistMedia.name === media.name;
      },
    );
    if (mediaToPlay) {
      state.playingMedia = mediaToPlay;
    }
    state.playing = true;
  },
};
