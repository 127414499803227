

























































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import { FieldStateMixin } from '../utils/FieldStateMixin';
import InvalidForm from './shared/InvalidForm.vue';

@Component({
  components: { InvalidForm },
  validations: {
    form: Array.from(new Array(39)).reduce(
      (previousValue, currentValue, index) => {
        if (index < 16) {
          return { ...previousValue, [`q${index + 1}`]: { required } };
        } else {
          return { ...previousValue, [`q${index + 1}`]: {} };
        }
      },
      {},
    ),
  },
})
export default class MusicForAllFormComponent extends Mixins(FieldStateMixin) {
  public questions: {
    [key: string]: {
      question: string;
      options?: Array<{ text: string; value: any }>;
    };
  } = {
    q1: {
      question:
        'În ce interval de vârstă te încadrezi? Alege opțiunea potrivită dintre cele de mai jos.',
      options: [
        { value: 1, text: '18 - 29 ani' },
        { value: 2, text: '30 - 39 ani' },
        { value: 3, text: '40 - 49 ani' },
        { value: 4, text: 'peste 50 ani' },
      ],
    },
    q2: {
      question:
        'În ce gen te încadrezi? Alege opțiunea potrivită dintre cele de mai jos.',
      options: [
        { text: 'Feminin', value: 1 },
        { text: 'Masculin', value: 2 },
        { text: 'Nu răspund', value: 3 },
      ],
    },
    q3: {
      question:
        'În ce mediu îți desfășori activitatea? Alege opțiunea potrivită dintre cele de mai jos',
      options: [
        { text: 'Urban', value: 1 },
        { text: 'Rural', value: 2 },
      ],
    },
    q4: {
      question:
        'Care este ultimul nivel de studii absolvit? Alege opțiunea potrivită dintre cele de mai jos.',
      options: [
        {
          text:
            'Studii universitare - specializare aferentă domeniului muzical',
          value: 1,
        },
        {
          text: 'Liceu de Artă - specializare aferentă domeniului muzical',
          value: 2,
        },
        { text: 'Facultate de Teologie', value: 3 },
        { text: 'Liceu/Seminar Teologic', value: 4 },
        { text: 'Cursuri de formare în domeniul dirijatului coral', value: 5 },
        { text: 'Alte studii în domeniul muzical', value: 6 },
        { text: 'Fără studii în domeniul muzical', value: 7 },
      ],
    },
    q5: {
      question:
        'Ai absolvit studii în domeniul pedagogiei? Alege opțiunea/opțiunile potrivită/potrivite dintre cele de mai jos.',
      options: [
        { text: 'Da, în domeniul pedagogiei', value: 1 },
        { text: 'Da, în domeniul psihopedagogiei', value: 2 },
        { text: 'Da, în domeniul psihopedagogiei speciale', value: 3 },
        { text: 'Nu', value: 4 },
      ],
    },
    q6: {
      question:
        'În calitate de dirijor de cor, care este activitatea ta profesională? Alege opțiunea potrivită dintre cele de mai jos.',
      options: [
        { text: 'Cadru didactic', value: 1 },
        { text: 'Dirijor din alte domenii', value: 2 },
      ],
    },
    q7: {
      question:
        'De cât timp activezi ca dirijor de cor? Alege opțiunea potrivită dintre cele de mai jos.',
      options: [
        { text: 'Sub 1 an', value: 1 },
        { text: 'Între 1 an și 2 ani', value: 2 },
        { text: 'Între 2 ani și 5 ani', value: 3 },
        { text: 'Peste 5 ani', value: 4 },
      ],
    },
    q8: {
      question:
        'Din experiența personală, cum se realizează comunicarea dintre dirijor și coriști, prin intermediul muzicii? Alege opțiunea potrivită dintre cele de mai jos.',
      options: [
        { text: 'Foarte ușor', value: 1 },
        { text: 'Ușor', value: 2 },
        { text: 'Neutru', value: 3 },
        { text: 'Dificil', value: 4 },
        { text: 'Foarte dificil', value: 5 },
      ],
    },
    q9: {
      question:
        'În experiența ta de lucru cu copii și tineri, ai surprins la aceștia reacții precum? Alege opțiunea/opțiunile potrivită/potrivite dintre cele de mai jos.',
      options: [
        { text: 'violență verbală', value: 1 },
        { text: 'violență fizică', value: 2 },
        { text: 'bullying manifestat față de alți copii', value: 3 },
        { text: 'imposibilitatea de a gestiona conflicte', value: 4 },
        { text: 'pierderea concentrării', value: 5 },
        { text: 'autostimulări', value: 6 },
        { text: 'tonul ridicat în timpul dialogului', value: 7 },
        { text: 'imposibilitatea de a se coordona cu ceilalți', value: 8 },
      ],
    },
    q10: {
      question:
        'Ai lucrat sau lucrezi cu copii și/sau tineri cu cerințe educaționale speciale, respectiv necesități educaționale complementare obiectivelor generale ale educației școlare, necesități care solicită o școlarizare adaptată particularităților individuale și/sau caracteristice unei deficiențe (ori tulburări de învățare), precum și o intervenție, prin reabilitare/recuperare corespunzătoare? Alege opțiunea potrivită dintre cele de mai jos.',
      options: [
        { text: 'Da', value: 1 },
        { text: 'Nu', value: 2 },
      ],
    },
    q11: {
      question:
        'În ce categorie de nevoi speciale consideri că se încadrează copiii și/sau tinerii cu care lucrezi?',
      options: [
        {
          text:
            'copii și tineri cu tulburări de conduită, hiperactivitate, deficit de atenție, comportament sfidător',
          value: 1,
        },
        {
          text:
            'copii și tineri ce manifestă anxietate, depresie sau atacuri de panică',
          value: 2,
        },
        { text: 'copii și tineri cu handicap asociat', value: 3 },
        {
          text:
            'copii și tineri cu dificultăţi de înțelegere, memorie și învățare',
          value: 4,
        },
        {
          text:
            'copii și tineri cu deficiențe sau întârziere în limbaj sau comunicare și interacțiune',
          value: 5,
        },
        {
          text: 'copii și tineri cu deficiențe motorii și sau de coordonare',
          value: 6,
        },
      ],
    },
    q12: {
      question:
        'Cum este interacțiunea cu copiii și tinerii cu cerințe educaționale speciale spre deosebire de cei care nu au cerințe educaționale speciale?',
      options: [
        { text: 'Foarte ușoară', value: 1 },
        { text: 'Ușoară', value: 2 },
        { text: 'Neutră', value: 3 },
        { text: 'Dificilă', value: 4 },
        { text: 'Foarte dificilă', value: 5 },
      ],
    },
    q13: {
      question:
        'Cum apreciezi atitudinea copiilor și/sau tinerilor cu cerințe educaționale speciale față de cursurile de cor pe care le organizezi?',
      options: [
        { text: 'Pozitivă', value: 1 },
        { text: 'Neutră', value: 2 },
        { text: 'Negativă', value: 3 },
      ],
    },
    q14: {
      question:
        'Din experiența ta profesională consideri că mediul familial este un factor important pentru dezvoltarea copiilor și a tinerilor cu cerințe educaționale speciale?',
      options: [
        { text: 'Da', value: 1 },
        { text: 'Nu', value: 2 },
      ],
    },
    q15: {
      question:
        'Care crezi că sunt motivele pentru care copiii și/sau tinerii cu cerințe educaționale speciale aleg sau sunt îndrumați de către familiile acestora să participe la activități muzicale?',
      options: [
        {
          text:
            'Dezvoltarea abilităților artistice, cu precădere a celor muzicale',
          value: 1,
        },
        { text: 'Dezvoltarea capacității de comunicare/relaționare', value: 2 },
        { text: 'Posibilitatea integrării sociale', value: 3 },
        { text: 'Alt motiv', value: 4 },
      ],
    },
    q16: {
      question:
        'Primești sprijin concret sau încurajări în activitățile artistice pe care le organizezi și în care implici copii și/sau tineri cu cerințe educaționale speciale?',
      options: [
        { text: 'Da', value: 1 },
        { text: 'Nu', value: 2 },
      ],
    },
    q17: {
      question:
        'Ce lucruri crezi că poți îmbunătăți acum în privința corului tău?',
    },
    q18: {
      question:
        'Care este cea mai mare provocare, în calitate de dirijor, atunci când lucrezi cu corul tău?',
    },
    q19: {
      question:
        'Imaginează-ți pentru câteva secunde că astăzi este prima ta zi ca dirijor al corului. Ce vezi diferit la acest grup de copii pe care deja îi cunoști?',
    },
    q20: {
      question:
        'Care a fost pentru tine momentul în care ai simțit că te poți dărui cu totul acestui cor?',
    },
    q21: {
      question: 'Ce așteptări crezi că au de la tine copiii din corul tău?',
    },
    q22: {
      question:
        'Ce simți acum că ai nevoie ca dirijor pentru a contribui și mai bine la dezvoltarea armonioasă a corului tău?',
    },
    q23: {
      question:
        'Ce crezi că i-ar ajuta pe copiii din corul tău în evoluția și dezvoltarea lor personală și corală?',
    },
    q24: {
      question:
        'Cât de bine crezi că s-au format relațiile de prietenie, de lucru și de colegialitate dintre copiii corului tău? Scrie un scurt exemplu.',
    },
    q25: {
      question:
        'Dacă ar fi să-i prezinți cuiva, care nu cunoaște corul tău, atmosfera generală din echipă, ce culori ai folosi pentru a face o descriere cât mai ancorată în realitate?',
    },
    q26: {
      question:
        'Imaginează-ți că unul dintre copiii din cor întrerupe brusc repetiția. Ce faci în acel moment?',
    },
    q27: {
      question:
        'Cum reacționează ceilalți copii atunci când un coleg de-ai lor întrerupe repetiția?',
    },
    q28: {
      question:
        'Cum îți reglezi starea emoțională când se întâmplă ceva neprevăzut cu unul dintre membrii corului tău?',
    },
    q29: {
      question:
        'Ce ai simțit atunci când, la finalul unei zile de repetiții, ora nu a decurs așa cum ți-ai propus?',
    },
    q30: {
      question:
        'Ai cerut vreodată ajutorul din partea unui membru al corului într-o situație de echipă mai dificil de gestionat? Dacă da, care a fost acea situație și cum te-a ajutat acel membru?',
    },
    q31: {
      question:
        'Dacă ar fi să adaugi, ca prin farmec, un singur lucru la colecția de calități a corului tău, care ar fi acel lucru?',
    },
    q32: {
      question:
        'Cum ați sărbătorit ultima dată succesul vostru alături de toți membrii corului?',
    },
    q33: {
      question:
        'Ce crezi că înțeleg copiii și/sau tinerii din corul tău în privința noțiunilor de echipă, incluziune și integrare?',
    },
    q34: {
      question:
        'Care este cea mai frumoasă experiență de progres a copiilor și/sau tinerilor cu cerințe educaționale speciale din corul tău?',
    },
    q35: {
      question: 'Ce crezi că a contribuit la acest progres?',
    },
    q36: {
      question:
        'Care crezi că sunt efectele benefice pentru ceilalți membrii ai corului în a cânta alături de copiii și/sau tineri cu cerințe educaționale speciale?',
    },
    q37: {
      question:
        'Și care crezi că sunt provocările pentru ceilalți membrii ai corului?',
    },
    q38: {
      question:
        'Care simți că sunt efectele benefice pentru tine, în calitate de  dirijor, în a lucra cu un cor din care fac parte copii și/sau tineri cu CES?',
    },
    q39: {
      question:
        'Și care sunt provocările pentru tine în lucrul cu copiii și/sau tinerii cu CES?',
    },
  };
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation };
    };
  public form = Array.from(new Array(39)).reduce(
    (previousValue, currentValue, index) => {
      return { ...previousValue, [`q${index + 1}`]: null };
    },
    {},
  );
  public textAreaGroup1 = Array.from(new Array(23).keys());
  public loading = false;
  public success = false;

  public getQuestion(index: number) {
    return this.questions[`q${index + 17}`].question;
  }

  public save() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      this.$http.post(this.$apiRoutes.questionnaire, this.form).then(() => {
        this.loading = false;
        this.form = Array.from(new Array(39)).reduce(
          (previousValue, currentValue, index) => {
            return { ...previousValue, [`q${index + 1}`]: null };
          },
          {},
        );
        this.success = true;
        this.$scrollTo('#app', 1000, {
          container: 'body',
          easing: 'ease-in-out',
          force: true,
          cancelable: false,
        });
        this.$nextTick(() => this.$v.$reset());
      });
    }
  }
}
