











import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Toolbar from '../components/account/Toolbar.vue';
import Drawer from '../components/account/Drawer.vue';
import EditAccount from '../components/account/EditAccount.vue';
import { BvModalEvent } from 'bootstrap-vue';

@Component({
  components: { Toolbar, EditAccount },
})
export default class RootAccount extends Vue {}
