import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Vuelidate from 'vuelidate';
import { Component } from 'vue-property-decorator';
import VueMq from 'vue-mq';
import { default as VueScrollTo } from 'vue-scrollto';
import VueMasonry from 'vue-masonry-css';
import moment from 'moment';
import 'moment/locale/ro';

import { ApiRoutesPlugin } from './api/apiRoutes';
import App from './App.vue';
import router from './router';
import store from './store';
import './bootstrap';
import './fontawesome';
import './axios';
import VueSignaturePad from 'vue-signature-pad';

Vue.use(Vuelidate);
Component.registerHooks(['validations']);

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.$cookies.config('30d');
Vue.use(VueMq, {
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity,
  },
});
Vue.use(VueScrollTo);
Vue.use(VueReCaptcha, {
  siteKey: '6LfxBeMUAAAAAM766RbEOaGmji3R2Icv_AE9fwn-',
  autoHideBadge: false,
});

Vue.use(ApiRoutesPlugin);
Vue.use(VueMasonry);
Vue.filter('formatDate', (value: Date, format?: string) => {
  if (!value) {
    return '';
  }
  return moment(value).format(format ? format : 'LL');
});

import VueGtag from 'vue-gtag';
Vue.use(
  VueGtag,
  {
    config: { id: 'UA-71308875-1', anonymize_ip: true },
    bootstrap: false,
  },
  router,
);

Vue.use(VueSignaturePad);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
