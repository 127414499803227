


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Testimonial } from '../../models/Testimonial';
import NextPrevButtons from './NextPrevButtons.vue';

@Component({
  components: { NextPrevButtons },
})
export default class TestimonialTemplate extends Vue {
  @Prop() public testimonials!: Testimonial[];
  @Prop() public fullWidthColor!: boolean;
  private index: number = 0;

  public get testimonial(): Testimonial {
    return this.testimonials[this.index];
  }

  public next() {
    this.index += 1;
    this.index %= this.testimonials.length;
  }

  public previous() {
    this.index -= 1;
    if (this.index < 0) {
      this.index = this.testimonials.length - 1;
    }
  }

  public getKey() {
    if (this.testimonials !== null) {
      if (this.testimonials[this.index].name) {
        return this.testimonials[this.index].name;
      } else {
        if (this.testimonials[this.index].translations.length > 0) {
          return this.testimonials[this.index].translations[0].text;
        }
      }
    }
    return 0;
  }
}
