
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Score, ScoreMp3 } from '../../../models/Library';
import { ScoresFilters } from '../../../models/Scores';
import { Filter, Filters } from '../../../models/Filters';
import { Action } from 'vuex-class';
import { MediaWrapper } from '../../../modules/media/types';

@Component({})
export default class ScoresTable extends Vue {
  public scores: Score[] = [];
  public filteredScores: Score[] = [];
  @Prop() public selectedFilters: ScoresFilters;
  @Prop() public filters: Filters;
  @Action('loadMedia') public loadMedia: (media: MediaWrapper) => void;
  public downloading: number[] = [];

  public fields = [
    {
      key: 'name',
      label: 'Nume',
      sortable: true,
      thClass: 'all-scores-table-head',
      tdClass: 'all-scores-table-column',
    },
    {
      key: 'author',
      label: 'Autor',
      sortable: true,
      thClass: 'all-scores-table-head',
      tdClass: 'all-scores-table-column',
    },
    {
      key: 'voices',
      label: 'Număr voci',
      thClass: 'all-scores-table-head',
      tdClass: 'all-scores-table-column',
    },
    {
      key: 'themes',
      label: 'Tematica',
      thClass: 'all-scores-table-head',
      tdClass: 'all-scores-table-column',
    },
    {
      key: 'difficulties',
      label: 'Dificultate',
      thClass: 'all-scores-table-head',
      tdClass: 'all-scores-table-column',
    },
    {
      key: 'choirTypes',
      label: 'Tip Cor',
      thClass: 'all-scores-table-head',
      tdClass: 'all-scores-table-column',
    },
    {
      key: 'actions',
      label: 'Descarcă',
      thClass: 'all-scores-table-head',
      tdClass: 'all-scores-table-column',
    },
  ];

  // PAGINATION
  public perPage: number = 10;
  public currentPage: number = 1;
  public loading: boolean = false;

  public mounted() {
    this.loading = true;
    this.$http.get(`/api/song/`).then((response: any) => {
      this.scores = response.data;
      this.loading = false;
      this.onFilterUpdate();
    });
  }

  @Watch('selectedFilters', { deep: true, immediate: true })
  public onFilterUpdate() {
    this.filteredScores = this.scores.filter((score: Score) => {
      let filter: boolean = true;
      if (this.selectedFilters.name) {
        filter =
          filter &&
          score.name
            .toLowerCase()
            .includes(this.selectedFilters.name.toLowerCase());
      }
      if (
        this.selectedFilters.choir_type &&
        this.selectedFilters.choir_type.length > 0
      ) {
        filter =
          filter &&
          this.selectedFilters.choir_type.filter((value) =>
            score.choir_type.includes(value),
          ).length > 0;
      }
      if (this.selectedFilters.voice && this.selectedFilters.voice.length > 0) {
        filter =
          filter &&
          this.selectedFilters.voice.filter((value) =>
            score.voice.includes(value),
          ).length > 0;
      }
      if (
        this.selectedFilters.difficulty &&
        this.selectedFilters.difficulty.length > 0
      ) {
        filter =
          filter &&
          this.selectedFilters.difficulty.filter((value) =>
            score.difficulty.includes(value),
          ).length > 0;
      }
      if (this.selectedFilters.theme && this.selectedFilters.theme.length > 0) {
        filter =
          filter &&
          this.selectedFilters.theme.filter((value) =>
            score.theme.includes(value),
          ).length > 0;
      }
      if (
        this.selectedFilters.file_type &&
        this.selectedFilters.file_type.length > 0
      ) {
        filter =
          filter &&
          this.selectedFilters.file_type.filter((value) => {
            if (value === 'mp3') {
              return score.has_mp3;
            } else if (value === 'pdf') {
              return score.has_files;
            }
          }).length > 0;
      }
      return filter;
    });
  }

  public get voiceMap(): { [id: string]: string } {
    const map: { [id: string]: string } = {};
    this.filters.voices.forEach((voice: Filter) => {
      map[voice.id] = voice.name;
    });
    return map;
  }

  public get themeMap(): { [id: number]: string } {
    const map: { [id: string]: string } = {};
    this.filters.themes.forEach((voice: Filter) => {
      map[voice.id] = voice.name;
    });
    return map;
  }

  public get difficultyMap(): { [id: string]: string } {
    const map: { [id: string]: string } = {};
    this.filters.difficulties.forEach((voice: Filter) => {
      map[voice.id] = voice.name;
    });
    return map;
  }

  public get choirTypeMap(): { [id: string]: string } {
    const map: { [id: string]: string } = {};
    this.filters.choir_types.forEach((voice: Filter) => {
      map[voice.id] = voice.name;
    });
    return map;
  }

  public getTheme(item: Score): string {
    return item.theme.map((value: number) => this.themeMap[value]).join(', ');
  }

  public getVoice(item: Score): string {
    return item.voice.map((value: number) => this.voiceMap[value]).join(', ');
  }

  public getDifficulty(item: Score): string {
    return item.difficulty
      .map((value: number) => this.difficultyMap[value])
      .join(', ');
  }

  public getChoirType(item: Score): string {
    return item.choir_type
      .map((value: number) => this.choirTypeMap[value])
      .join(', ');
  }

  public selectScore(score: Score) {
    this.loadMedia({
      name: score.name,
      media: score.mp3s.map((mp3: ScoreMp3) => {
        return {
          name: mp3.title,
          file: mp3.file,
          type: 'audio',
        };
      }),
    });
  }

  public downloadFile(score: Score) {
    this.downloading = [...this.downloading, score.id];
    this.$http
      .post(this.$apiRoutes.download('score', score.id), null, {
        responseType: 'blob',
      })
      .then((response: any) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${score.name}.zip`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(() => {
        this.$bvToast.toast('Fișierul nu a putut fi descărcat', {
          title: 'Eroare',
          variant: 'danger',
        });
      })
      .finally(() => {
        this.downloading = this.downloading.filter(
          (item: number) => item !== score.id,
        );
      });
  }

  public isDownloading(score: Score) {
    return this.downloading.find((item: number) => item === score.id);
  }
}
