





























































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import AddNewChoirStepOne from './AddNewChoirStepOne.vue';
import AddNewChoirStepTwo from './AddNewChoirStepTwo.vue';
import { AddNewChoir } from '../../../models/AddNewChoir';
import * as authActions from '../../../modules/user/actions';

@Component({
  components: {
    AddNewChoirStepOne,
    AddNewChoirStepTwo,
  },
})
export default class AddChoir extends Vue {
  public choir: AddNewChoir = {
    name: null,
    entity: null,
    protocol_number: null,
    city: null,
    address: null,
    county: null,
    contact: null,
    phone: null,
    email: null,
    choir_members: null,
  };
  @Action(authActions.LOAD_CHOIRS) public loadChoirs: () => void;

  public saving = false;

  public $refs!: Vue['$refs'] & {
    stepOne: AddNewChoirStepOne & { isValid: () => boolean };
    stepTwo: AddNewChoirStepTwo & { isValid: () => boolean };
  };
  public step = 1;

  public performNextStep() {
    if (this.step === 1 && this.$refs.stepOne.isValid()) {
      this.step++;
    } else if (this.step === 2 && this.$refs.stepTwo.isValid()) {
      this.submit();
    }
  }

  public performPreviousStep() {
    if (this.step === 1) {
      this.$bvModal.hide('add-choir-modal');
    } else {
      this.step--;
    }
  }

  public submit() {
    this.saving = true;
    this.$http.post(this.$apiRoutes.choir.createChoir, this.choir).then(
      (response: any) => {
        this.$bvToast.toast(
          'Corul a fost creat cu succes. Vei fi notificat prin email atunci când va fi confirmat.',
          {
            title: 'Adaugă cor',
            variant: 'success',
            solid: true,
          },
        );
        this.resetForm();
        this.loadChoirs();
      },
      (error: any) => {
        this.$bvToast.toast(
          'Corul nu a putut fi creat. Te rugăm să încerci mai târziu. Dacă problemă persistă te rugăm să ne' +
            ' contactezi.',
          {
            title: 'Adaugă cor',
            variant: 'danger',
            solid: true,
            noAutoHide: true,
          },
        );
        this.resetForm();
        this.loadChoirs();
      },
    );
    this.$bvModal.hide('add-choir-modal');
  }

  private resetForm() {
    this.step = 1;
    this.saving = false;
    this.choir = {
      name: null,
      entity: null,
      protocol_number: null,
      city: null,
      address: null,
      county: null,
      contact: null,
      phone: null,
      email: null,
      choir_members: null,
    };
  }
}
