
























































































































































































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import {
  email,
  maxLength,
  minLength,
  numeric,
  required,
} from 'vuelidate/lib/validators';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import FormAgreement from '../FormAgreement.vue';
import { FieldStateMixin } from '../../../utils/FieldStateMixin';
import InvalidForm from '../../shared/InvalidForm.vue';
import { Workshop, WorkshopDate } from '../../../models/workshop';

@Component({
  components: {
    FormAgreement,
    InvalidForm,
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
        numeric,
      },
      age: { required },
      school: { required },
      workplace: { required },
      knowledge: { required },
      experience: { required },
      workshops: { required },
    },
  },
})
export default class WorkshopForm extends Mixins(FieldStateMixin) {
  public get workshops(): Workshop[] {
    return this.date?.workshops || [];
  }

  private static getEmptyForm(): WorkshopFormModel {
    return {
      name: null,
      email: null,
      phone: null,
      age: null,
      school: null,
      workplace: null,
      knowledge: null,
      experience: null,
      agreed: null,
      workshops: [],
    };
  }
  @Prop() public date: WorkshopDate | null;
  @Prop() public loading: boolean;

  public form: WorkshopFormModel = WorkshopForm.getEmptyForm();
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key in keyof WorkshopFormModel]: Validation };
    };
  @Ref() public agreement: FormAgreement & {
    touch: () => void;
    invalid: () => boolean;
    reset: () => void;
  };
  public serverError: boolean = false;
  public success: boolean = false;
  public busy: boolean = false;

  public submit() {
    this.$v.$touch();
    this.agreement.touch();
    if (!this.$v.$invalid && !this.agreement.invalid()) {
      this.busy = true;
      this.serverError = false;
      this.success = false;
      this.$http
        .post(this.$apiRoutes.signUps.workshops, this.form)
        .then(() => {
          this.success = true;
          this.form = WorkshopForm.getEmptyForm();
        })
        .catch(() => (this.serverError = true))
        .finally(() => {
          this.$v.$reset();
          this.agreement.reset();
          this.busy = false;
          scrollTo(0, 0);
        });
    }
  }
}

interface WorkshopFormModel {
  name: string;
  email: string;
  phone: string;
  age: number;
  school: string;
  workplace: string;
  knowledge: string;
  experience: string;
  agreed: boolean;
  workshops: number[];
}
