











import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import AudioPlayer from './components/AudioPlayer.vue';
import Playlist from './components/Playlist.vue';
import CookiePolicy from './views/CookiePolicy.vue';
import { bootstrap } from 'vue-gtag';
import ScrollToTop from './components/ScrollToTop.vue';
@Component({
  components: { CookiePolicy, Playlist, AudioPlayer, ScrollToTop },
})
export default class App extends Vue {
  @Getter('isAuthenticated') public isAuthenticated?: boolean;
  @Getter('hasMedia') public hasMedia: boolean;
  @Getter('showPlaylist') public showPlaylist: boolean;
  @Action('loadCounties') public loadCounties: () => void;

  public showCookieConsent: boolean = false;

  public mounted() {
    this.checkCookies();
    this.loadCounties();
    this.axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        if (error.response === undefined) {
          this.$bvToast.toast(
            'A apărut o eroare de comunicare cu serverul. Vă rugăm să reîncărcați pagina sau să încercați mai târziu.',
            {
              title: 'Eroare de server',
              variant: 'danger',
              solid: true,
              noAutoHide: true,
            },
          );
        }
        return Promise.reject(error);
      },
    );
  }

  @Watch('isAuthenticated')
  public watch(event: any) {
    if (this.isAuthenticated) {
      if (this.$router.currentRoute.query.next) {
        this.$router.push(this.$router.currentRoute.query.next.toString());
      } else {
        this.$router.push('/cont/dashboard/');
      }
    } else {
      this.$router.push('/cont/login/');
    }
  }

  private checkCookies() {
    const cookieConsent = this.$cookies.get('__cookie_consent');
    if (cookieConsent) {
      this.showCookieConsent = false;
      const allowed: string[] = JSON.parse(cookieConsent);
      if (allowed.includes('statistics')) {
        bootstrap();
      }
    } else {
      this.showCookieConsent = true;
    }
  }
}
