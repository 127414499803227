import Vue from 'vue';
import Router, { Route, RouteRecord } from 'vue-router';
import Home from './views/presentation/Home.vue';
import Mission from './views/presentation/about_cantus_mundi/Mission.vue';
import Team from './views/presentation/about_cantus_mundi/Team.vue';
import Supporters from './views/presentation/about_cantus_mundi/Supporters.vue';
import Partners from './views/presentation/about_cantus_mundi/Partners.vue';
import BrandingKit from './views/presentation/about_cantus_mundi/BrandingKit.vue';
import Activities from './views/presentation/events/Activities.vue';
import News from './views/presentation/events/News.vue';
import Announcements from './views/presentation/events/Announcements.vue';
import MediaAppearances from './views/presentation/events/MediaAppearances.vue';
import CantusMundiBucharest from './views/presentation/CantusMundiBucharest.vue';
import NewsDetails from './views/presentation/events/NewsDetails.vue';
import AccountRoot from './views/AccountRoot.vue';
import Login from './views/account/auth/Login.vue';
import Dashboard from './views/account/account/Dashboard.vue';
import PresentationRoot from './views/PresentationRoot.vue';
import AboutCantusMundi from './views/presentation/AboutCantusMundi.vue';
import EventsRoot from './views/presentation/EventsRoot.vue';
import AuthRoot from './views/account/AuthRoot.vue';
import LoggedInRoot from './views/account/LoggedInRoot.vue';
import store from './store';
import Events from './views/account/account/Events.vue';
import Library from './views/account/account/Library.vue';
import MyChoir from './views/account/account/MyChoir.vue';
import Resources from './views/account/account/Resources.vue';
import ContactMadrigalist from './views/account/account/ContactMadrigalist.vue';
import SignUp from './views/account/auth/SignUp.vue';
import PasswordReset from './views/account/auth/PasswordReset.vue';
import EmailSent from './views/account/auth/EmailSent.vue';
import NotFound from './components/NotFound.vue';
import Activate from './views/account/account/Activate.vue';
import PasswordResetConfirm from './views/account/auth/PasswordResetConfirm.vue';
import Scores from './views/account/account/library/Scores.vue';
import Audiobooks from './views/account/account/library/Audiobooks.vue';
import VideoLessons from './views/account/account/library/VideoLessons.vue';
import LibrarySummary from './components/account/library/LibrarySummary.vue';
import Legislation from './views/presentation/Legislation.vue';
import PrivacyPolicy from './views/presentation/PrivacyPolicy.vue';
import AuditionForm from './components/events/AuditionForm.vue';
import WorkshopSignup from './components/events/workshop/WorkshopSignup.vue';
import { Position } from 'vue-router/types/router';
import PersonalDataAgreement from './views/presentation/PersonalDataAgreement.vue';
import CampaignForm from './components/events/CampaignForm.vue';
import MusicForAllFormComponent from './components/MusicForAllFormComponent.vue';
import RomanianLottery from './components/events/RomanianLottery.vue';
import Preselections from './components/events/Preselections.vue';
import Volunteering from './components/about_cantus_mundi/Volunteering.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to: Route, from: Route, savedPosition: void | Position) {
    if (to.name !== from.name) {
      return { x: 0, y: 0 };
    }
    return null;
  },
  routes: [
    {
      path: '/cont',
      component: AccountRoot,
      children: [
        {
          path: '',
          component: AuthRoot,
          children: [
            {
              path: 'login',
              name: 'login',
              component: Login,
            },
            {
              path: 'nou',
              name: 'register',
              component: SignUp,
            },
            {
              path: 'reseteaza-parola',
              name: 'reset-password',
              component: PasswordReset,
            },
            {
              path: 'reseteaza-parola/:token',
              name: 'reset-password-confirm',
              component: PasswordResetConfirm,
            },
            {
              path: 'email-trimis',
              name: 'email-sent',
              component: EmailSent,
            },
            {
              path: 'activate/:code',
              name: 'activate',
              component: Activate,
              alias: '/activate/:code',
            },
          ],
          beforeEnter: (
            to: Route,
            from: Route,
            next: (path?: string) => void,
          ) => {
            if (!store.getters.isAuthenticated) {
              next();
              return;
            }
            next('/cont/dashboard');
          },
        },
        {
          path: '',
          component: LoggedInRoot,
          children: [
            {
              path: 'dashboard',
              name: 'dashboard',
              component: Dashboard,
            },
            {
              path: 'evenimente',
              name: 'events',
              component: Events,
            },
            {
              path: 'librarie',
              component: Library,
              children: [
                {
                  path: '',
                  name: 'library',
                  component: LibrarySummary,
                },
                {
                  path: 'partituri',
                  name: 'scores',
                  component: Scores,
                },
                {
                  path: 'audiobooks',
                  name: 'audiobooks',
                  component: Audiobooks,
                },
                {
                  path: 'video-lectii',
                  name: 'videolessons',
                  component: VideoLessons,
                },
              ],
            },
            {
              path: 'corul-meu',
              name: 'my-choir',
              component: MyChoir,
            },
            {
              path: 'resurse',
              name: 'resources',
              component: Resources,
            },
            {
              path: 'contacteaza-un-madrigalist',
              name: 'contact-madrigalist',
              component: ContactMadrigalist,
            },
          ],
          beforeEnter: (
            to: Route,
            from: Route,
            next: (path?: string) => void,
          ) => {
            if (store.getters.isAuthenticated) {
              next();
              return;
            }
            next(`/cont/login?next=${to.path}`);
          },
        },
      ],
    },
    {
      path: '/',
      component: PresentationRoot,
      meta: {
        title: 'Cantus Mundi',
      },
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {
            title: 'Acasă',
          },
        },
        {
          path: 'despre-cantus-mundi',
          component: AboutCantusMundi,
          children: [
            {
              path: 'misiune',
              name: 'mission',
              component: Mission,
              meta: {
                title: 'Misiune',
              },
            },
            {
              path: 'echipa',
              name: 'team',
              component: Team,
            },
            {
              path: 'voluntariat',
              name: 'volunteering',
              component: Volunteering,
            },
            {
              path: 'sustinatori',
              name: 'testimonials',
              component: Supporters,
            },
            {
              path: 'parteneri',
              name: 'partners',
              component: Partners,
            },
            {
              path: 'pachet-media',
              name: 'branding-kit',
              component: BrandingKit,
            },
          ],
        },
        {
          path: 'evenimente',
          component: EventsRoot,
          children: [
            {
              path: 'activitati',
              name: 'activities',
              component: Activities,
            },
            {
              path: 'stiri',
              name: 'news',
              component: News,
            },
            {
              path: 'stiri/:slug',
              name: 'news-details',
              component: NewsDetails,
            },
            {
              path: 'noutati',
              name: 'announcements',
              component: Announcements,
            },
            {
              path: 'aparitii-media',
              name: 'media-appearances',
              component: MediaAppearances,
            },
            {
              path: 'loteria-romana',
              name: 'romanian-lottery',
              component: RomanianLottery,
            },
          ],
        },
        {
          path: 'bucuresti',
          name: 'cantus-mundi-bucharest',
          component: CantusMundiBucharest,
        },
        {
          path: 'legistie-si-transparenta',
          name: 'legislation',
          component: Legislation,
        },
        {
          path: 'politica-de-confidentialitate',
          name: 'privacy-policy',
          component: PrivacyPolicy,
        },
        {
          path: 'auditii',
          name: 'auditions',
          component: AuditionForm,
        },
        {
          path: 'ateliere',
          name: 'workshop',
          component: WorkshopSignup,
        },
        {
          path: 'campanie',
          name: 'campaign',
          component: CampaignForm,
        },
        {
          path: 'formular-gdpr',
          name: 'personal-data-agreement',
          component: PersonalDataAgreement,
        },
        {
          path: 'chestionar',
          name: 'form',
          component: MusicForAllFormComponent,
        },
        {
          path: 'preselectii',
          name: 'preselections',
          component: Preselections,
        },        
      ],
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r: RouteRecord) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  let title: string = null;
  to.matched
    .slice()
    .reverse()
    .forEach((r: RouteRecord) => {
      if (r.meta && r.meta.title) {
        if (title === null) {
          title = r.meta.title;
        } else {
          title += ` | ${r.meta.title}`;
        }
      }
    });
  if (title) {
    document.title = `${title}`;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]'),
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: any) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

export default router;
