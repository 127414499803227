
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ListItem from './ListItem.vue';
import ListActionComp from './ListActionComp.vue';
import { User } from '../../../models/Auth';
import { AudioBook, AudioBookMp3 } from '../../../models/Library';
import BoxHeader from './BoxHeader.vue';
import { Choir } from '../../../models/Choir';
import { MediaWrapper } from '../../../modules/media/types';

@Component({
  components: { ListItem, ListActionComp, BoxHeader },
})
export default class Audiobooks extends Vue {
  @Getter('userDetails') public userDetails: User;
  @Getter('selectedChoir') public selectedChoir: Choir;
  @Prop({ default: 5 }) public count: number;
  @Action('loadMedia') public loadMedia: (media: MediaWrapper) => void;

  public audiobooks: AudioBook[] = [];
  public loading: boolean = true;
  public downloading: number[] = [];

  @Watch('selectedChoir', { immediate: true })
  public onSelectedChoirUpdate() {
    this.audiobooks = [];
    this.loading = true;
    this.$http.get(this.$apiRoutes.dashboard.audiobook).then(
      (response: any) => {
        this.loading = false;
        this.audiobooks = response.data.slice(0, this.count);
      },
      (error: any) => {
        this.loading = false;
      },
    );
  }

  public selectAudiobook(audiobook: AudioBook) {
    this.loadMedia({
      name: audiobook.name,
      media: audiobook.mp3s.map((mp3: AudioBookMp3) => {
        return {
          name: mp3.name,
          file: mp3.file,
          type: 'audio',
        };
      }),
    });
  }

  public downloadFile(audioBook: AudioBook) {
    this.downloading = [...this.downloading, audioBook.id];
    this.$http
      .post(this.$apiRoutes.download('audiobook', audioBook.id), null, {
        responseType: 'blob',
      })
      .then((response: any) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${audioBook.name}.zip`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .finally(() => {
        this.downloading = this.downloading.filter(
          (item: number) => item !== audioBook.id,
        );
      });
  }

  public isDownloading(audioBook: AudioBook) {
    return this.downloading.find((item: number) => item === audioBook.id);
  }
}
