
























import { Component, Ref, Vue } from 'vue-property-decorator';
import VideoPlayer from '../components/VideoPlayer.vue';
import Navbar from '../components/Navbar.vue';
import CmFooter from '../components/CmFooter.vue';
import { BModal } from 'bootstrap-vue';
import { email, required } from 'vuelidate/lib/validators';
import InvalidForm from '../components/shared/InvalidForm.vue';
import ContactForm from '../components/ContactForm.vue';

@Component({
  components: {
    InvalidForm,
    VideoPlayer,
    CmFooter,
    ContactForm,
    Navbar,
  },
})
export default class PresentationRoot extends Vue {
  @Ref('contact-modal') public readonly contactModal!: BModal;

  public mounted() {
    this.$root.$on('openContact', () => this.showModal());
  }

  public showModal() {
    this.contactModal.show();
  }

  public closeModal() {
    this.contactModal.hide();
  }

  public get hasVideo(): boolean {
    return !!this.$route.query.watchVideo;
  }
}
