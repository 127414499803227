import { TestimonialTranslation } from './TestimonialTranslation';

export class Testimonial {
  public image?: string;
  public name: string;
  public order: number;
  public translations: TestimonialTranslation[];

  constructor(opts: {
    image?: string;
    name: string;
    order: number;
    translations: TestimonialTranslation[];
  }) {
    this.image = opts.image;
    this.name = opts.name;
    this.order = opts.order;
    this.translations = opts.translations;
  }
}
