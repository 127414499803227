



































import { Component, Prop, Vue } from 'vue-property-decorator';
import ListActionComp from './ListActionComp.vue';
import BoxHeader from './BoxHeader.vue';
import { VideoLesson } from '../../../models/VideoLesson';
import { AxiosResponse } from 'axios';
import ListItem from './ListItem.vue';

import * as mediaActions from '../../../modules/media/actions';
import { Action } from 'vuex-class';
import { MediaWrapper } from '../../../modules/media/types';

@Component({
  components: { ListItem, BoxHeader, ListActionComp },
})
export default class VideoLessons extends Vue {
  @Prop({ default: 5 }) public count: number;
  @Action(mediaActions.LOAD_MEDIA) public loadMedia: (
    media: MediaWrapper,
  ) => void;
  public loading: boolean = false;
  public videoLessons: VideoLesson[] = null;

  public mounted() {
    this.loading = true;
    this.$http
      .get<VideoLesson[]>(this.$apiRoutes.videoLessons.list)
      .then((response: AxiosResponse<VideoLesson[]>) => {
        this.videoLessons = response.data.slice(0, this.count);
      })
      .finally(() => (this.loading = false));
  }

  public playVideo(video: VideoLesson) {
    const media: MediaWrapper = {
      media: [
        {
          name: video.title,
          ogg: video.ogg,
          webm: video.webm,
          mp4: video.mp4,
          type: 'video',
        },
      ],
      name: video.title,
    };
    this.loadMedia(media);
  }
}
