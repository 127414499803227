import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import store from './store';

Vue.use(VueAxios, axios);

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (error && error.response && error.response.status === 401) {
      store.dispatch('logout');
    }
    return Promise.reject(error);
  },
);

axios.interceptors.request.use((config) => {
  const token = Vue.$cookies.get('__cm_tkn');
  config.headers.Authorization = `Token ${token}`;
  return config;
});
