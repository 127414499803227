





































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ObjectiveItem } from '../../models/Objectives';

@Component
export default class ObjectiveItemComp extends Vue {
  @Prop({ required: true }) public data: ObjectiveItem;
  @Prop({ required: true }) public nextData: ObjectiveItem;
  @Prop({ required: true }) public position: number;
  @Prop({ required: true }) public total?: number;
}
