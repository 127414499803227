
























import { Component, Vue } from 'vue-property-decorator';
import TeamMember from '../../../components/about_cantus_mundi/team/TeamMember.vue';
import CallToAction from '../../../components/shared/CallToAction.vue';
import FullWidthTitle from '../../../components/shared/FullWidthTitle.vue';

@Component({
  components: { TeamMember, FullWidthTitle, CallToAction },
})
export default class Team extends Vue {
  private teamMembers: TeamMemberObject[] = [
    new TeamMemberObject(
      require('@/assets/images/about-cantus-mundi/team/ion-marin.png'),
      'Ion Marin',
      'Președinte Fondator',
      'Personalitate importantă a scenei muzicale internaționale, Ion Marin este unul dintre puținii dirijori' +
        ' care au atins celebritatea la nivel mondial, atât în aria simfonică, precum și în cea a operei.' +
        ' Deschiderea sa amplă către repertorii simfonice și vocal-simfonice, face ca toate reprezentațiile' +
        ' Maestrului Ion Marin să fie exemple de creativitate, inovație și angajament. Contribuția dumnealui' +
        ' la existența acestui proiect este esențială, Programul Național Cantus Mundi fiind inițiat de' +
        ' domnia sa.',
    ),
    new TeamMemberObject(
      require('@/assets/images/about-cantus-mundi/team/anna-ungureanu.png'),
      'Anna Ungureanu',
      'Director Artistic',
      'Specializată în arta dirijorală sub coordonarea renumiților muzicieni Marin Constantin și Ion ' +
        'Marin, Anna Ungureanu a susținut concerte în numeroase țări din Europa și Asia. Începând din ' +
        '2014, Anna Ungureanu, doctor în muzică și dirijor principal al Corului Național de Cameră ' +
        '„Madrigal - Marin Constantin”, a fost numită Director Artistic al Programului Național Cantus ' +
        'Mundi, inițiat de dirijorul Ion Marin. Pe lângă impresionanta carieră în calitate de dirijor coral, ' +
        'este și un formator care inspiră generațiile viitoare de dirijori de cor din România, susținând ' +
        'periodic cursuri în cadrul Programului Național Cantus Mundi. De asemenea, participă în mod ' +
        'constant la festivaluri de muzică corală, organizate la nivel național și internțional, în calitate de ' +
        'membră a juriului.',
    ),
    new TeamMemberObject(
      require('@/assets/images/about-cantus-mundi/team/emil-pantelimon.png'),
      'Emil Pantelimon',
      'Manager Corul Național de Cameră Madrigal-Marin Constantin',
      'Începându-și cariera la o vârstă foarte tânără, Emil Pantelimon a coordonat, în ultimii 15 ani,' +
        ' nenumărate programe educaționale și culturale pentru tineret. A lucrat în cabinetele a doi miniștri' +
        ' ai culturii în România, în echipa de comunicare și artele spectacolului. Cu o licență în Științe' +
        ' Politice și o acreditare în Politici Externe și Diplomație, el creează medii orientate spre' +
        ' rezultate în ﬁecare echipă pe care o conduce. Emil Pantelimon este, din 2015, managerul Corului' +
        ' Național de Cameră „Madrigal - Marin Constantin”. Viziunea sa de management are impact asupra' +
        ' miilor de copii din toată țara, care își văd visul cu ochii, atunci când urcă alături de Corul' +
        ' Național de Cameră „Madrigal - Marin Constantin”, pe aceeași scenă, prin intermediul Programului' +
        ' Național Cantus Mundi.',
    ),
    new TeamMemberObject(
      require('@/assets/images/about-cantus-mundi/team/leila-popovici.png'),
      'Leila Popovici',
      'Director Program',
      'Cu peste un deceniu de experiență în coordonarea profesioniștilor din IT în mediul corporatist,' +
        ' Leila Popovici urmărește să atingă și să mențină eﬁciența în ﬁecare proiect nou la care aderă.' +
        ' Licențiată în Studii Economice și în Management, deține un master în Managementul Resurselor Umane' +
        ' și în Comunicare Managerială. Sub coordonarea sa, Programul Național Cantus Mundi a dobândit noi' +
        ' repere, orientarea sa spre rezultate ﬁind unul dintre factorii cheie pentru succesul programului.' +
        ' Scopul său principal este să le dea copiilor din România vocea de care au nevoie pentru a depăși' +
        ' toate formele de discriminare - cântând împreună, într-un glas - astfel alocând o mare parte din' +
        ' energia sa schimbării în bine a României.',
    ),
  ];
}

class TeamMemberObject {
  public image: string;
  public name: string;
  public position: string;
  public description: string;

  constructor(
    image: string,
    name: string,
    position: string,
    description: string,
  ) {
    this.image = image;
    this.name = name;
    this.position = position;
    this.description = description;
  }
}
