











import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NextPrevButtons extends Vue {
  @Prop() private msg!: string;

  @Emit() public previous() {}

  @Emit() public next() {}
}
