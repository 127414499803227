



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Choir } from '../models/Choir';

@Component
export default class UnconfirmedChoir extends Vue {
  @Prop() private selectedChoir: Choir;
}
