



















import { Component, Vue } from 'vue-property-decorator';
import MediaAppearanceItem from '../../../components/events/MediaAppearanceItem.vue';
import FullWidthTitle from '../../../components/shared/FullWidthTitle.vue';
import { MediaAppearance } from '../../../models/MediaAppearance';

@Component({
  components: { MediaAppearanceItem, FullWidthTitle },
})
export default class MediaAppearances extends Vue {
  public mediaAppearances: MediaAppearance[] = [];

  public mounted() {
    this.$http.get('api/media-appearances/').then((response) => {
      const items: MediaAppearance[] = [];
      response.data.forEach((item: any) => {
        items.push(new MediaAppearance(item));
      });
      this.mediaAppearances = items;
    });
  }
}
