



































import { Component, Vue } from 'vue-property-decorator';
import SectionHeader from '../shared/SectionHeader.vue';

@Component({
  components: { SectionHeader },
})
export default class Volunteering extends Vue {}
