import { MutationTree } from 'vuex';
import { DefinitionState } from './types';

export const mutations: MutationTree<DefinitionState> = {
  setCounties(s, payload) {
    s.counties.data = payload;
    s.counties.isLoading = false;
  },
  loadingCounties(s) {
    s.counties.data = [];
    s.counties.isLoading = true;
  },
  errorCounties(s) {
    s.counties.data = [];
    s.counties.isLoading = false;
  },
};
