










































































import { Component, Vue } from 'vue-property-decorator';
import SectionHeader from '../../../components/shared/SectionHeader.vue';
import Spacer from '../../../components/shared/Spacer.vue';
import FullWidthTitle from '../../../components/shared/FullWidthTitle.vue';

@Component({
  components: { SectionHeader, FullWidthTitle, Spacer },
})
export default class Partners extends Vue {}
