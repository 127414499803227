























import { Component, Vue } from 'vue-property-decorator';
import FullWidthTitle from '../../../components/shared/FullWidthTitle.vue';
import { Testimonial } from '../../../models/Testimonial';
import CallToAction from '../../../components/shared/CallToAction.vue';
import Supporter from '../../../components/about_cantus_mundi/supporters/Supporter.vue';
import { TestimonialTranslation } from '../../../models/TestimonialTranslation';

@Component({
  components: { Supporter, CallToAction, FullWidthTitle },
})
export default class Supporters extends Vue {
  public supporters: Testimonial[] = [];

  public mounted() {
    this.$http.get(this.$apiRoutes.supporters).then((response) => {
      response.data.forEach((item: any) => {
        const trans: TestimonialTranslation[] = [];
        item.translations.forEach((t: any) => {
          trans.push(new TestimonialTranslation(t.language, t.text));
        });
        this.supporters.push(
          new Testimonial({
            image: item.image,
            name: item.name,
            order: item.order,
            translations: trans,
          }),
        );
      });
    });
  }
}
