


















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import UnconfirmedChoir from '../../../components/UnconfirmedChoir.vue';
import PosterResourcesBox from '../../../components/account/resources/PosterResourcesBox.vue';
import BrandMaterialsBox from '../../../components/account/resources/BrandMaterialsBox.vue';
import OtherBox from '../../../components/account/resources/OtherBox.vue';
import { Choir } from '../../../models/Choir';
@Component({
  components: {
    UnconfirmedChoir,
    OtherBox,
    BrandMaterialsBox,
    PosterResourcesBox,
  },
})
export default class Resources extends Vue {
  @Getter('selectedChoir') public selectedChoir: Choir;
}
