









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BoxHeader extends Vue {
  @Prop() public title!: string;
  @Prop() public count!: string;
}
