
















import { Component, Vue } from 'vue-property-decorator';
import FullWidthTitle from '../../shared/FullWidthTitle.vue';
import { WorkshopDateResponse } from '../../../models/responses/workshop';
import { AxiosResponse } from 'axios';
import { Workshop, WorkshopDate } from '../../../models/workshop';
import WorkshopDetails from './WorkshopDetails.vue';
import WorkshopForm from './WorkshopForm.vue';

@Component({
  components: {
    WorkshopForm,
    WorkshopDetails,
    FullWidthTitle,
  },
})
export default class WorkshopSignup extends Vue {
  public date: WorkshopDate | null = null;
  public loading = true;

  public mounted() {
    this.$http
      .get<WorkshopDateResponse[]>(this.$apiRoutes.signUps.workshops)
      .then((response: AxiosResponse<WorkshopDateResponse[]>) => {
        const dates = response.data.map<WorkshopDate>((d) => ({
          availabilityStart: new Date(d.availability_start),
          availabilityEnd: new Date(d.availability_end),
          date: new Date(d.date),
          workshops: d.workshops.map<Workshop>((w) => ({
            id: w.id,
            title: w.title,
            description: w.description,
            date: new Date(`${d.date}T${w.hour}`),
          })),
        }));
        this.date = dates.length > 0 ? dates[0] : null;
      })
      .finally(() => (this.loading = false));
  }
}
