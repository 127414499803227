

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { CalendarEvent } from '../../models/Event';

@Component
export default class DayDetails extends Vue {
  @Prop() public selectedDay: Date;
  @Prop() public events: CalendarEvent[];

  public displayDate(): string {
    return moment(this.selectedDay).format('dddd, Do MMMM YYYY');
  }

  public getEvents(): CalendarEvent[] {
    return this.events.filter((event: CalendarEvent) => {
      if (event.startDate === null) {
        return false;
      }
      if (event.endDate) {
        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        return (
          new Date(startDate.setHours(0, 0, 0, 0)) <= this.selectedDay &&
          new Date(endDate.setHours(0, 0, 0, 0)) >= this.selectedDay
        );
      } else {
        const startDate = new Date(event.startDate);
        return new Date(startDate.setHours(0, 0, 0, 0)) === this.selectedDay;
      }
    });
  }

  public parseDate(date: Date): string {
    return moment(date).format('LT');
  }
}
