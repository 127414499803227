import { ActionTree } from 'vuex';
import axios, { AxiosError } from 'axios';
import { DefinitionState } from './types';
import { RootState } from '../../store';
import { ApiRoutes } from '../../api/apiRoutes';

export const actions: ActionTree<DefinitionState, RootState> = {
  loadCounties({ commit }): void {
    commit('loadingCounties');
    axios({
      url: ApiRoutes.account.getCounties,
      method: 'GET',
    }).then(
      (response: any) => {
        const payload = response && response.data;
        commit('setCounties', payload);
      },
      (error: any) => {
        commit('errorCounties');
      },
    );
  },
};
