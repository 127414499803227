

















import { Component, Vue } from 'vue-property-decorator';
import NextPrevButtons from '../../components/home/NextPrevButtons.vue';
import { ObjectiveItem } from '../../models/Objectives';
import ObjectiveItemComp from './ObjectiveItemComp.vue';

@Component({
  components: { ObjectiveItemComp, NextPrevButtons },
})
export default class Objectives extends Vue {
  public objectiveItems: ObjectiveItem[] = [];
  public displayIndex: number = 0;

  public mounted() {
    this.$http.get(this.$apiRoutes.objectives).then((response) => {
      const items: ObjectiveItem[] = [];
      response.data.forEach((item: any) => {
        items.push(new ObjectiveItem(item));
      });
      this.objectiveItems = items;
    });
  }

  public nextObjective() {
    if (this.objectiveItems) {
      this.displayIndex = (this.displayIndex + 1) % this.objectiveItems.length;
    }
  }

  public previousObjective() {
    if (this.objectiveItems) {
      this.displayIndex -= 1;
      if (this.displayIndex < 0) {
        this.displayIndex = this.objectiveItems.length - 1;
      }
    }
  }

  public getNextItem(index: number): ObjectiveItem {
    let nextIndex = index + 1;
    if (nextIndex >= this.objectiveItems.length) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = this.objectiveItems.length - 1;
    }
    return this.objectiveItems[nextIndex];
  }
}
