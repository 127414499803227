













































































































































import { Component, Emit, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { User } from '../../models/Auth';
import * as userGetters from '../../modules/user/getters';
import { Choir } from '../../models/Choir';
import EditAccount from './EditAccount.vue';

@Component
export default class Drawer extends Vue {
  public get disabled(): boolean {
    return (
      !this.choirs ||
      this.choirs.length === 0 ||
      !(
        this.selectedChoir &&
        this.selectedChoir.is_confirmed &&
        this.selectedChoir.user_is_confirmed
      )
    );
  }
  @Getter(userGetters.USER_DETAILS) public userDetails: User;
  @Getter(userGetters.USER_CHOIRS) public choirs: Choir[];
  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;
  @Action('logout') public logout: () => void;
  @Emit('change') public onSidebarChange(visible: boolean) {}
}
