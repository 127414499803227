

























import { Component, Prop, Vue } from 'vue-property-decorator';
import Spacer from './Spacer.vue';

@Component({
  components: { Spacer },
})
export default class SectionHeader extends Vue {
  @Prop() private title!: string;
  @Prop() private description!: string;
}
