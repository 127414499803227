










import { Component, Prop, Vue } from 'vue-property-decorator';
import SectionHeader from '../shared/SectionHeader.vue';
import Spacer from '../shared/Spacer.vue';

@Component({
  components: { SectionHeader, Spacer },
})
export default class Program extends Vue {
  @Prop() private msg!: string;
}
