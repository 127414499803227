






































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MultiSelect extends Vue {
  @Prop() private label: string;
  @Prop() private placeholder: string;
  @Prop() private buttonText: string;
  @Prop() private description: string;
  @Prop() private emptyText: string;
  @Prop() private items: string[];
  private item: string = '';
  @Emit('addItem') public addItem() {
    const item = this.item;
    this.item = '';
    return item;
  }
  @Emit('removeItem') public removeItem(item: string) {
    return item;
  }
}
