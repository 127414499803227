































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Media, Video } from '../modules/media/types';

import * as mediaActions from '../modules/media/actions';
import * as mediaGetters from '../modules/media/getters';

@Component
export default class AudioPlayer extends Vue {
  @Getter(mediaGetters.IS_PLAYING) public isPlaying: boolean;
  @Getter(mediaGetters.PLAYING) public playing: Media | Video | null;
  @Getter(mediaGetters.HAS_NEXT) public hasNext: boolean;
  @Getter(mediaGetters.HAS_PREVIOUS) public hasPrevious: boolean;

  @Action(mediaActions.TOGGLE) public toggle: () => {};
  @Action(mediaActions.NEXT) public next: () => {};
  @Action(mediaActions.PREVIOUS) public previous: () => {};
  @Action(mediaActions.PAUSE) public pause: () => {};
  @Action(mediaActions.TOGGLE_PLAYLIST) public togglePlaylist: () => {};
  @Action(mediaActions.CLOSE_PLAYLIST) public closePlaylist: () => {};
  @Action(mediaActions.REMOVE_MEDIA) public removeMedia: () => {};

  public time: number;
  public progressPercent: number = 0;
  public durationDisplay: string;

  public $refs!: Vue['$refs'] & {
    audio: HTMLAudioElement;
    video: HTMLVideoElement;
  };

  @Watch('playing') public watchPlaying(nv: Media | Video, ov: Media | Video) {
    if (nv.type !== ov.type) {
      switch (nv.type) {
        case 'audio':
          this.$refs.video.pause();
          break;
        case 'video':
          this.$refs.audio.pause();
          break;
      }
    }
    if (this.isPlaying) {
      this.ref.load();
    }
  }

  @Watch('isPlaying') public watchIsPlaying() {
    if (this.isPlaying) {
      this.ref.play();
    } else {
      this.ref.pause();
    }
  }

  public mounted() {
    this.$refs.audio.addEventListener('timeupdate', this.progressUpdate);
    this.$refs.video.addEventListener('timeupdate', this.progressUpdate);
  }

  public performNext() {
    if (this.hasNext) {
      this.next();
    }
  }

  public performPrevious() {
    if (this.hasPrevious) {
      this.previous();
    }
  }

  public closePlayer() {
    this.closePlaylist();
    this.removeMedia();
  }

  public get isAudio(): boolean {
    return this.playing.type === 'audio';
  }

  public get isVideo(): boolean {
    return this.playing.type === 'video';
  }

  public get ref(): HTMLAudioElement | HTMLVideoElement {
    return this.isAudio ? this.$refs.audio : this.$refs.video;
  }

  public toggleFullscreen() {
    if (this.isVideo) {
      const elem: (HTMLAudioElement | HTMLVideoElement) & {
        mozRequestFullScreen?: () => void;
        webkitRequestFullscreen?: () => void;
        msRequestFullscreen?: () => void;
      } = this.ref;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    }
  }

  public progressClick(data: MouseEvent) {
    const width =
      (data.target as HTMLElement).getAttribute('class') === 'progress'
        ? (data.target as HTMLElement).offsetWidth
        : (data.target as HTMLElement).parentElement.offsetWidth;
    this.ref.currentTime = this.ref.duration * (data.offsetX / width);
  }

  private progressUpdate(event: Event) {
    const element = event.target as HTMLAudioElement | HTMLVideoElement;
    this.time = element.currentTime;
    this.progressPercent = (this.time / element.duration) * 100.0;
    if (!this.durationDisplay) {
      this.durationDisplay = this.convert(element.duration);
    }
    if (this.progressPercent === 100) {
      if (this.hasNext) {
        this.performNext();
      } else {
        this.pause();
      }
    }
  }

  private convert(duration: number): string {
    try {
      const hhmmss = new Date(duration * 1000).toISOString().substr(11, 8);
      return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss;
    } catch (e) {
      return '';
    }
  }
}
