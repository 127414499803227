










































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import GdprAgree from '../../components/account/GdprAgree.vue';
import AddChoir from '../../components/account/addChoir/AddChoir.vue';
import FinalizeAccount from '../../components/account/FinalizeAccount.vue';
import { FormError } from '../../modules/utils';
import { User } from '../../models/Auth';
import { Choir } from '../../models/Choir';
import EditAccount from '../../components/account/EditAccount.vue';
import * as userGetters from '../../modules/user/getters';
import * as userActions from '../../modules/user/actions';
import Drawer from '../../components/account/Drawer.vue';
import { BvModalEvent } from 'bootstrap-vue';

@Component({
  components: { EditAccount, GdprAgree, FinalizeAccount, AddChoir, Drawer },
})
export default class LoggedInRoot extends Vue {
  public get disabled(): boolean {
    return (
      !this.choirs ||
      this.choirs.length === 0 ||
      !(
        this.selectedChoir &&
        this.selectedChoir.is_confirmed &&
        this.selectedChoir.user_is_confirmed
      )
    );
  }

  public get isCompleted() {
    return !this.userDetails || this.userDetails.isCompleted;
  }
  @Getter(userGetters.AUTH_ERROR) public userDetailsError: FormError | null;
  @Getter(userGetters.USER_DETAILS) public userDetails?: User;
  @Getter(userGetters.USER_CHOIRS) public userChoirs?: Choir[];
  @Action(userActions.LOAD_USER_DETAILS) public getUserDetails: () => void;
  @Action(userActions.LOAD_CHOIRS) public loadChoirs: () => void;
  @Action(userActions.LOGOUT) public logout: any;
  @Getter(userGetters.USER_CHOIRS) public choirs: Choir[];
  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;
  public overlayVisibility: boolean = false;
  public $refs!: Vue['$refs'] & {
    editAccount: EditAccount & { save: () => void };
  };
  private gdprAgreeError: boolean = false;

  public saveAccount(event: BvModalEvent) {
    event.preventDefault();
    this.$refs.editAccount.save();
  }

  public onDrawerVisibility(visible: boolean) {
    this.overlayVisibility = visible;
  }

  @Watch('userDetailsError')
  public handleUserDetailsError() {
    this.$bvModal.show('userDetailsError');
  }

  @Watch('userDetails', { immediate: true })
  public handleUserDetails() {
    const showGDPR = this.showGdprAgree();
  }

  public mounted() {
    if (!this.userDetails) {
      this.getUserDetails();
    }
    if (!this.userChoirs) {
      this.loadChoirs();
    }

    this.$root.$on('bv::modal::hidden', (bvEvent: Event, modalId: string) => {
      if (modalId === 'userDetailsError') {
        this.logout();
      }
    });
  }

  public gdprAgree(event: Event) {
    event.preventDefault();
    this.$http.post('api/account/agree_gdpr/').then(
      (response: any) => {
        this.getUserDetails();
      },
      (error: any) => {
        this.gdprAgreeError = true;
      },
    );
  }

  public gdprReject(event: Event) {
    this.logout();
  }

  private showGdprAgree(): boolean {
    if (this.userDetails && this.userDetails.status === 'gdpr-agree') {
      this.$bvModal.show('gdprAgree');
      return true;
    } else {
      this.$bvModal.hide('gdprAgree');
      return false;
    }
  }
}
