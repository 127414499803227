


















import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import UnconfirmedChoir from '../../../components/UnconfirmedChoir.vue';
import { Choir } from '../../../models/Choir';
import * as userGetters from '../../../modules/user/getters';
import { RedirectMixin } from '../../../utils/RedirectMixin';
@Component({
  components: { UnconfirmedChoir },
})
export default class Library extends Mixins(RedirectMixin) {
  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;

  public getRouteName() {
    switch (this.$route.name) {
      case 'scores':
        return 'Partituri';
      case 'audiobooks':
        return 'Audio Book';
      case 'videolessons':
        return 'Lecții video';
      default:
        return null;
    }
  }
}
