












































































































































































































import { Component, Vue } from 'vue-property-decorator';
import SectionHeader from '../../../components/shared/SectionHeader.vue';
import FullWidthTitle from '../../../components/shared/FullWidthTitle.vue';
import CallToAction from '../../../components/shared/CallToAction.vue';
import TestimonialTemplate from '../../../components/home/TestimonialTemplate.vue';
import FullWidthText from '../../../components/shared/FullWidthText.vue';
import { Testimonial } from '../../../models/Testimonial';
import { TestimonialTranslation } from '../../../models/TestimonialTranslation';

@Component({
  components: {
    SectionHeader,
    FullWidthTitle,
    FullWidthText,
    TestimonialTemplate,
    CallToAction,
  },
})
export default class Mission extends Vue {
  public testimonial: Testimonial[];

  constructor() {
    super();
    this.testimonial = [];
    const translation = new TestimonialTranslation(
      'ro',
      'Și pentru că ne dorim ca, în următorii ani, în România să existe măcar un cor în fiecare școală, o ' +
        'altă direcție importantă de dezvoltare în desfășurare este cartografierea rețelei corale școlare ' +
        'din țara noastră, cu obiectivul de a integra peste 4.000 de dirijori și 250.000 de copii în ' +
        'Programul Național Cantus Mundi.',
    );
    const testimonial = new Testimonial({
      name: 'Ion Marin - dirijor, fondator Cantus Mundi',
      order: 1,
      translations: [translation],
    });
    this.testimonial.push(testimonial);
  }
}
