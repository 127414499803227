import { GetterTree } from 'vuex';
import Vue from 'vue';
import { UserState } from './types';
import { RootState } from '../../store';
import { FormError } from '../utils';
import { User } from '../../models/Auth';
import { Choir } from '../../models/Choir';

export const IS_AUTHENTICATING = 'isAuthenticating';
export const IS_AUTHENTICATED = 'isAuthenticated';
export const AUTH_ERROR = 'authError';
export const IS_LOADING_USER_DETAILS = 'isLoadingUserDetails';
export const USER_DETAILS = 'userDetails';
export const USER_CHOIRS = 'userChoirs';
export const SELECTED_CHOIR = 'selectedChoir';
export const IS_CONDUCTOR = 'isConductor';

export const getters: GetterTree<UserState, RootState> = {
  [IS_AUTHENTICATING](state) {
    return state.authenticating;
  },
  [IS_AUTHENTICATED](state): boolean {
    if (!state.token && Vue.$cookies.get('__cm_tkn')) {
      state.token = Vue.$cookies.get('__cm_tkn');
    }
    return !!state.token;
  },
  [AUTH_ERROR](state): FormError | null {
    return state.error;
  },
  [IS_LOADING_USER_DETAILS](state) {
    return state.loadingUserDetails;
  },
  [USER_DETAILS](state): User | null {
    return state.userDetails;
  },
  [USER_CHOIRS](state): Choir[] | null {
    return state.userChoirs;
  },
  [SELECTED_CHOIR](state): Choir | null {
    return state.selectedChoir;
  },
  [IS_CONDUCTOR](state: UserState) {
    return (
      state.selectedChoir &&
      state.userDetails &&
      state.userDetails.conductorIn.includes(state.selectedChoir.id)
    );
  },
};
