export class Image {
  public image: string;

  constructor(image: string) {
    this.image = image;
  }
}

export class News {
  public id?: string;
  public title?: string;
  public category?: string;
  public frontPageImage?: string;
  public location?: string;
  public date?: string;
  public slug?: string;
  public banner?: string;
  public firstTextArea?: string;
  public secondTextArea?: string;
  public quote?: string;

  public images: Image[] = [];
  public video: {
    mp4: string;
    webm: string;
    ogg: string;
  };

  constructor(data: RawNewsResponse) {
    this.id = data.id;
    this.title = data.title;
    this.category = data.category;
    this.frontPageImage = data.front_page_image;
    this.location = data.location;
    this.date = data.date;
    this.slug = data.slug;
    this.banner = data.banner;
    this.firstTextArea = data.first_text_area;
    this.secondTextArea = data.second_text_area;
    this.quote = data.quote;
    if (data.hasOwnProperty('video') && data.video) {
      this.video = {
        mp4: data.video.mp4,
        webm: data.video.webm,
        ogg: data.video.ogg,
      };
    }
    if (data.hasOwnProperty('gallery') && data.gallery) {
      data.gallery.images.forEach((image) => {
        this.images.push(new Image(image.image));
      });
    }
  }

  public getCategoryDisplay(): string {
    switch (this.category) {
      case 'contest': {
        return 'Concurs';
      }
      case 'gala': {
        return 'Gală';
      }
      case 'shop': {
        return 'Atelier';
      }
      case 'choir': {
        return 'Coruri';
      }
      case 'festival': {
        return 'Festival';
      }
      case 'tour': {
        return 'Turnee';
      }
      case 'video': {
        return 'Video';
      }
      case 'photo': {
        return 'Foto';
      }
    }
  }
}

export class Contest extends News {}

export class Video extends News {}

export class FrontPageNews {
  public contest?: Contest;
  public videos: Video[] = [];
  public news: News[] = [];

  public addNews(news: News) {
    this.news.push(news);
  }

  public addVideo(video: Video) {
    this.videos.push(video);
  }

  public addRawData(data: RawNewsResponse) {
    if (data.category === 'video') {
      this.addVideo(new Video(data));
    } else if (data.category === 'contest') {
      this.contest = new Contest(data);
    } else {
      this.addNews(new News(data));
    }
  }
}

export class Category {
  public key: string;
  public value: string;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}

export class RawNewsResponse {
  public id?: string;
  public title?: string;
  public category?: string;
  public location?: string;
  public date?: string;
  // tslint:disable-next-line:variable-name
  public front_page_image?: string;
  // tslint:disable-next-line:variable-name
  public first_text_area?: string;
  // tslint:disable-next-line:variable-name
  public second_text_area?: string;
  public quote?: string;
  public slug?: string;
  public banner?: string;

  public video?: {
    mp4?: string;
    ogg?: string;
    webm?: string;
  };

  public gallery?: {
    images: Array<{
      description: string;
      image: string;
      title: string;
    }>;
  };
}
