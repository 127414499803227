import { render, staticRenderFns } from "./PersonalDataAgreement.vue?vue&type=template&id=79bf52ff&scoped=true&"
import script from "./PersonalDataAgreement.vue?vue&type=script&lang=ts&"
export * from "./PersonalDataAgreement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79bf52ff",
  null
  
)

export default component.exports