import Vue from 'vue';
import Vuex from 'vuex';
import { user } from './modules/user';
import { definitions } from './modules/definitions';
import { media } from './modules/media';

Vue.use(Vuex);

export interface RootState {
  version: string;
}

export default new Vuex.Store<RootState>({
  state: {
    version: '1.0.0',
  },
  mutations: {},
  actions: {},
  modules: {
    user,
    definitions,
    media,
  },
});
