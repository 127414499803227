












































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component
export default class Activate extends Vue {
  public loading: boolean = true;
  public activated: boolean = false;
  public remaining = 10;

  public mounted() {
    this.loading = true;
    this.$http.post(`api/auth/${this.$route.params.code}/activate/`).then(
      (response: any) => {
        this.loading = false;
        this.activated = true;
        setInterval(this.decreaseTime, 1000);
      },
      (error: any) => {
        this.loading = false;
        this.activated = false;
      }
    );
  }

  public decreaseTime() {
    this.remaining--;
    if (this.remaining === 0) {
      this.$router.push({ name: 'login', query: { activated: 'true' } });
    }
  }
}
