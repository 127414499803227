
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { MediaAppearance } from '../../models/MediaAppearance';

@Component
export default class MediaAppearanceItem extends Vue {
  @Prop() public data?: MediaAppearance;
}
