

























import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Choir } from '../../../models/Choir';
import UnconfirmedChoir from '../../../components/UnconfirmedChoir.vue';
@Component({
  components: { UnconfirmedChoir },
})
export default class ContactMadrigalist extends Vue {
  @Getter('selectedChoir') public selectedChoir: Choir;
}
