







































import { Component, Prop, Vue } from 'vue-property-decorator';
import Spacer from '../shared/Spacer.vue';

@Component({
  components: { Spacer },
})
export default class PreselectionsCallToAction extends Vue {
  @Prop() private msg!: string;
}
