



















































































































































import { Component, Vue } from 'vue-property-decorator';
import CallToAction from '../../../components/shared/CallToAction.vue';
import SectionHeader from '../../../components/shared/SectionHeader.vue';
import Activity from '../../../components/events/Activity.vue';
import AuditionForm from '../../../components/events/AuditionForm.vue';

@Component({
  components: { AuditionForm, CallToAction, Activity, SectionHeader },
})
export default class Activities extends Vue {
  public openContact() {
    this.$root.$emit('openContact');
  }
}
