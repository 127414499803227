






















































































import { Component, Emit, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import ChoirSelect from './ChoirSelect.vue';
import { User } from '../../models/Auth';
import { Choir } from '../../models/Choir';
import EditAccount from './EditAccount.vue';
import { BvModalEvent } from 'bootstrap-vue';

@Component({
  components: { ChoirSelect, EditAccount },
})
export default class Toolbar extends Vue {
  @Getter('isAuthenticated') public isAuthenticated?: boolean;
  @Getter('userDetails') public userDetails: User;
  @Getter('userChoirs') public choirList: Choir[];
  @Getter('selectedChoir') public selectedChoir: Choir;
  @Mutation('choirSelect') public selectChoir: (elem: any) => void;

  public mounted() {}

  public get confirmedChoirs(): Choir[] {
    return this.choirList.filter((choir) => {
      return choir.user_is_confirmed;
    });
  }

  public get unconfirmedChoirs(): Choir[] {
    return this.choirList.filter((choir) => {
      return !choir.user_is_confirmed;
    });
  }

  public changeChoir(choirId: number) {
    this.selectChoir(this.choirList.find((_) => _.id === choirId));
  }

  public get name() {
    if (!!this.userDetails) {
      return `${this.userDetails.firstName} ${this.userDetails.lastName}`;
    }
    return 'Utilizator';
  }
}
