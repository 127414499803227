



















































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import jwt_decode from 'jwt-decode';
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import { RegisterRequest } from '../../../models/Auth';
import { FieldStateMixin } from '../../../utils/FieldStateMixin';
import InvalidForm from '../../../components/shared/InvalidForm.vue';

@Component({
  components: { InvalidForm },
  validations: {
    password: {
      required,
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      sameAs: sameAs('password'),
    },
  },
})
export default class PasswordResetConfirm extends Mixins(FieldStateMixin) {
  public password: string = null;
  public confirmPassword: string = null;
  public canReset: boolean = true;
  public claims: any;
  public serverError: boolean = false;
  public success: boolean = false;
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      password: Validation;
      passwordConfirm: Validation;
    };
  public username: string = null;

  public mounted() {
    this.claims = jwt_decode(this.$route.params.token);
    this.username = this.claims.email;
    if (this.claims.exp < Date.now() / 1000) {
      this.canReset = false;
    }
  }

  public resetPassword(event: Event) {
    event.preventDefault();

    if (this.claims.exp < Date.now() / 1000) {
      this.canReset = false;
      this.$root.$bvToast.toast(
        'Linkul a expirat! Te rugăm să reiei procesul.',
        { variant: 'danger', title: 'Eroare' },
      );
      return;
    }
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$http
        .post('api/auth/reset_password_confirm/', {
          password: this.password,
          token: this.$route.params.token,
        })
        .then(
          (response: any) => {
            this.success = true;
          },
          (error: any) => {
            this.serverError = true;
          },
        );
    }
  }
}
