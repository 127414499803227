
















































































































































import { Component, Vue } from 'vue-property-decorator';
import FullWidthTitle from '../../../components/shared/FullWidthTitle.vue';

@Component({
  components: { FullWidthTitle },
})
export default class BrandingKit extends Vue {}
