



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { News } from '../../../models/News';

@Component
export default class MediaItemTemplate extends Vue {
  @Prop() public data?: News;
}
