










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DateComp extends Vue {
  @Prop() public day!: string;
  @Prop() public month!: string;
  @Prop() public reversed: boolean;
}
