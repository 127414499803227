























































import { Component, Prop, Vue } from 'vue-property-decorator';
import Spacer from './Spacer.vue';

@Component({
  components: { Spacer },
})
export default class CallToAction extends Vue {
  @Prop() private msg!: string;
}
