

















































































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { minLength, numeric, required } from 'vuelidate/lib/validators';
import { Validation, validationMixin } from 'vuelidate';
import { Getter } from 'vuex-class';
import { AddNewChoir } from '../../../models/AddNewChoir';
import { County } from '../../../models/Map';
import { LoadingState } from '../../../modules/utils';
import { FieldStateMixin } from '../../../utils/FieldStateMixin';
import InvalidForm from '../../shared/InvalidForm.vue';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';

@Component({
  components: { InvalidForm },
  validations: {
    choir: {
      name: { required, minLength: minLength(5) },
      entity: { required, minLength: minLength(5) },
      protocol_number: {},
      choir_members: { required, numeric },
      city: { required, minLength: minLength(3) },
      address: { required, minLength: minLength(5) },
      county: { required },
    },
  },
  mixins: [validationMixin],
})
export default class AddNewChoirStepOne extends Mixins(FieldStateMixin) {
  @Prop() public choir: AddNewChoir;
  @Getter('counties') public counties: LoadingState<County>;
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation } & AddNewChoir;
    };

  public isValid(): boolean {
    this.$v.$touch();
    return !this.$v.$invalid;
  }
}
