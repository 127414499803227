






















































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LoadingState } from '../../modules/utils';
import { County } from '../../modules/definitions/types';
import * as userActions from '../../modules/user/actions';

@Component
export default class ChoirSelect extends Vue {
  @Getter('counties') public counties: LoadingState<County[]>;
  @Action(userActions.LOAD_CHOIRS) public loadChoirs: () => void;

  public get canAddChoirs(): boolean {
    return !(this.unconfirmedChoirs > 2);
  }

  public get countyMap(): { [id: string]: County } {
    const map: { [id: string]: County } = {};
    this.counties.data.forEach((county: County) => {
      map[county.value] = county;
    });
    return map;
  }

  public loading: boolean = false;
  public choirs: Array<{
    name: string;
    city: string;
    county: string;
    isMember: boolean;
    isConfirmed: boolean;
    _rowVariant?: string;
  }> = [];

  public filter: {
    name?: string;
    city?: string;
    county: string | null;
  } = { county: null };
  public fields = [
    {
      key: 'name',
      label: 'Nume',
    },
    {
      key: 'city',
      label: 'Oraș',
    },
    {
      key: 'county',
      label: 'Județ',
    },
    {
      key: 'actions',
      label: 'Acțiuni',
    },
  ];
  public page: number = 1;
  public count: number = 1;
  public unconfirmedChoirs: number = 0;

  public get countyOptions(): Array<{ value: string | null; text: string }> {
    const options: Array<{ value: string | null; text: string }> = [
      {
        value: null,
        text: 'Județ',
      },
      ...JSON.parse(JSON.stringify(this.counties.data)),
    ];
    return options;
  }

  public filterData(value: any) {
    let filterName = true;
    let filterCity = true;
    let filterCounty = true;
    if (this.filter.name) {
      filterName = value.name
        .toLowerCase()
        .includes(this.filter.name.toLowerCase());
    }
    if (this.filter.city) {
      filterCity = value.city
        .toLowerCase()
        .includes(this.filter.city.toLowerCase());
    }
    if (this.filter.county) {
      filterCounty = value.county
        .toLowerCase()
        .includes(this.filter.county.toLowerCase());
    }

    return filterName && filterCity && filterCounty;
  }

  public onFiltered(filteredItems: any) {
    this.count = filteredItems.length;
    this.page = 1;
  }

  public mounted() {
    this.loadAllChoirs();
  }

  public joinChoir(choirId: number) {
    this.$http.post(this.$apiRoutes.account.joinChoir, { choir: choirId }).then(
      (response: any) => {
        this.loadAllChoirs();
        this.loadChoirs();
      },
      (error: any) => {},
    );
  }

  public removeChoir(choirId: number) {
    this.$http
      .post(this.$apiRoutes.account.removeChoir, { choir: choirId })
      .then(
        (response: any) => {
          this.loadAllChoirs();
          this.loadChoirs();
        },
        (error: any) => {},
      );
  }

  private loadAllChoirs() {
    this.unconfirmedChoirs = 0;
    this.loading = true;
    this.$http.get(this.$apiRoutes.choir.loadAll).then(
      (response: any) => {
        this.loading = false;
        this.choirs = response.data.map((item: any) => {
          if (item.user_is_confirmed) {
            item._rowVariant = 'info';
          } else if (item.is_member) {
            this.unconfirmedChoirs++;
            item._rowVariant = 'warning';
          }
          item.isMember = item.is_member;
          item.isConfirmed = item.user_is_confirmed;
          return item;
        });
        this.choirs.sort((first: any, second: any) => {
          if (first.isConfirmed && second.isConfirmed) {
            return 0;
          } else if (first.isConfirmed && !second.isConfirmed) {
            return -1;
          } else if (!first.isConfirmed && second.isConfirmed) {
            return 1;
          } else if (first.isMember && second.isMember) {
            return 0;
          } else if (first.isMember && !second.isMember) {
            return -1;
          } else if (!first.isMember && second.isMember) {
            return 1;
          }
          return 0;
        });
        this.count = this.choirs.length;
      },
      (error: any) => {
        this.loading = false;
      },
    );
  }
}
