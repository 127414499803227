import { ActionTree } from 'vuex';
import axios, { AxiosError } from 'axios';
import Vue from 'vue';
import { UserState } from './types';
import { RootState } from '../../store';
import { Credentials, Token } from '../../models/Auth';
import { ApiRoutes } from '../../api/apiRoutes';
import { Choir } from '../../models/Choir';

import * as mutations from './mutations';

export const LOGIN = 'login';
export const LOAD_USER_DETAILS = 'userDetails';
export const LOAD_CHOIRS = 'loadChoirs';
export const LOAD_CHOIR_DETAILS = 'loadChoir';
export const LOGOUT = 'logout';

export const actions: ActionTree<UserState, RootState> = {
  [LOGIN]({ commit, dispatch }, credentials: Credentials): void {
    commit(mutations.AUTHENTICATING);
    axios({
      url: 'api/token-auth/',
      method: 'POST',
      data: {
        username: credentials.email,
        password: credentials.password,
      },
    }).then(
      (response: any) => {
        const payload: Token = response && response.data;
        Vue.$cookies.set('__cm_tkn', response.data.token);
        commit(mutations.LOGIN_DONE, payload);
        dispatch(LOAD_CHOIRS);
        dispatch(LOAD_USER_DETAILS);
      },
      (error: any) => {
        commit(mutations.LOGIN_ERROR, error.response);
      },
    );
  },
  [LOAD_USER_DETAILS]({ commit }): void {
    commit(mutations.LOADING_USER_DETAILS);
    axios({
      url: 'api/account/details/',
      method: 'GET',
    }).then(
      (response: any) => {
        const payload = response && response.data;
        commit(mutations.USER_DETAILS, {
          id: payload.id,
          firstName: payload.first_name,
          lastName: payload.last_name,
          avatar: payload.avatar,
          status: payload.status,
          birthDate: payload.birth_date,
          sex: payload.sex,
          phone: payload.phone,
          county: payload.judet,
          city: payload.localitate,
          isCompleted: payload.is_completed,
          conductorIn: payload.conductor_in,
        });
      },
      (error) => {
        commit(mutations.USER_DETAILS_ERROR, error.response);
      },
    );
  },
  [LOAD_CHOIRS]({ commit }): void {
    axios({
      url: 'api/account/choirs/',
      method: 'GET',
    })
      .then((response: any) => {
        const payload: Choir[] = response && response.data;
        commit(mutations.LOAD_CHOIRS, payload);
      })
      .catch((error: AxiosError) => {});
  },
  [LOAD_CHOIR_DETAILS]({ commit }, choidId: number): void {
    Vue.axios({
      url: ApiRoutes.choir.details(choidId),
      method: 'GET',
    }).then((response: any) => {
      const payload = response && response.data;
      commit(mutations.LOAD_CHOIR, payload);
    });
  },
  [LOGOUT]({ commit }): void {
    Vue.$cookies.remove('__cm_tkn');
    commit(mutations.LOGOUT);
  },
};
