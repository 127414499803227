import { render, staticRenderFns } from "./GdprAgree.vue?vue&type=template&id=c4350d4a&scoped=true&"
import script from "./GdprAgree.vue?vue&type=script&lang=ts&"
export * from "./GdprAgree.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4350d4a",
  null
  
)

export default component.exports