



























import { Component, Vue, Watch } from 'vue-property-decorator';
import ScoresTable from '../../../../components/account/library/ScoresTable.vue';
import FiltersBox from '../../../../components/account/library/FiltersBox.vue';
import { ScoresFilters } from '../../../../models/Scores';
import { Filters } from '../../../../models/Filters';

@Component({
  components: { ScoresTable, FiltersBox },
})
export default class Scores extends Vue {
  private static getEmptyFilter(): ScoresFilters {
    return {
      voice: [],
      theme: [],
      difficulty: [],
      choir_type: [],
      file_type: [],
      name: null,
    };
  }
  public filter: ScoresFilters = Scores.getEmptyFilter();
  public filters: Filters = null;
  public filterLoading: boolean = false;

  public mounted() {
    this.filterLoading = true;
    this.getFilters();
  }

  public getFilters() {
    this.$http
      .get(`/api/song/filters`)
      .then(
        (response: any) => {
          this.filters = response.data;
        },
        (error: any) => {},
      )
      .finally(() => (this.filterLoading = false));
  }

  public resetFilters() {
    this.filter = Scores.getEmptyFilter();
  }
}
