


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ScrollToTop extends Vue {
  public opacity: number = 0;
  public mounted() {
    window.addEventListener('scroll', this.updateScroll);
    this.updateScroll();
  }

  public scrollToTop() {
    this.$scrollTo('#app', 1000, {
      container: 'body',
      easing: 'ease-in-out',
      force: true,
      cancelable: false,
    });
  }

  private updateScroll() {
    let opacity =
      (window.scrollY - window.innerHeight / 2) / (window.innerHeight / 2);
    opacity = opacity < 0 ? 0 : opacity > 1 ? 1 : opacity;
    this.opacity = opacity;
  }
}
