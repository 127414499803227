










































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import MultiSelect from '../MultiSelect.vue';
import { Choir } from '../../../models/Choir';
import { County } from '../../../modules/definitions/types';
import { LoadingState } from '../../../modules/utils';

@Component({
  components: {
    MultiSelect,
  },
  validations: {},
})
export default class EditChoirModal extends Vue {
  @Getter('selectedChoir') public selectedChoir: Choir;
  @Getter('counties') public counties: LoadingState<County[]>;
  @Action('loadChoir') public loadChoir: (choirId: number) => void;
  public $refs!: Vue['$refs'] & {
    file: {
      files: any;
    };
  };
  public aboutChoirForm: Choir = null;

  @Watch('selectedChoir', { immediate: true }) public watchSelectedChoir() {
    this.aboutChoirForm = JSON.parse(JSON.stringify(this.selectedChoir));
    if (this.aboutChoirForm && !this.aboutChoirForm.awards) {
      this.aboutChoirForm.awards = [];
    }
    if (this.aboutChoirForm && !this.aboutChoirForm.participation) {
      this.aboutChoirForm.participation = [];
    }
  }

  public uploadFile() {
    if (!this.selectedChoir || !this.selectedChoir.id) {
      return;
    }
    const formData = new FormData();
    formData.append('image', this.$refs.file.files[0]);
    this.$http
      .post(this.$apiRoutes.choir.image(this.selectedChoir.id), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        this.loadChoir(this.selectedChoir.id);
      })
      .catch(() => {});
  }

  private addParticipation(participation: string) {
    this.aboutChoirForm.participation = [
      ...this.aboutChoirForm.participation,
      participation,
    ];
  }

  private removeParticipation(removeParticipation: string) {
    this.aboutChoirForm.participation = this.aboutChoirForm.participation.filter(
      (participation) => removeParticipation !== participation,
    );
  }

  private addAward(award: string) {
    this.aboutChoirForm.awards = [...this.aboutChoirForm.awards, award];
  }

  private removeAward(removeAward: string) {
    this.aboutChoirForm.awards = this.aboutChoirForm.awards.filter(
      (award) => award !== removeAward,
    );
  }

  private save() {
    this.$http
      .patch(
        this.$apiRoutes.choir.details(this.selectedChoir.id),
        this.aboutChoirForm,
      )
      .then((response: any) => {
        this.loadChoir(this.selectedChoir.id);
      })
      .catch((error: any) => {})
      .finally(() => {
        this.$bvModal.hide('edit-choir-modal');
      });
  }
}
