export class LoadingState<T> {
  public data: T;
  public isLoading: boolean;

  constructor(data: T) {
    this.data = data;
    this.isLoading = false;
  }
}

export interface FormError {
  nonFieldErrors: string[];
  [key: string]: string[];
}
