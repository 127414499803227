



































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import { Validation } from 'vuelidate';
import { FieldStateMixin } from '../../utils/FieldStateMixin';

@Component
export default class InvalidForm extends Mixins(FieldStateMixin) {
  @Prop({ required: true }) public field: Validation & {
    minLength?: boolean;
    required?: boolean;
    requiredIf?: boolean;
    maxLength?: boolean;
    sameAs?: boolean;
    numeric?: boolean;
    between?: boolean;
    email?: boolean;
    selected?: boolean;
  };
  @Prop({ default: null }) public serverError: string[];

  public get serverErrors() {
    if (this.serverError) {
      return this.serverError;
    } else {
      return [];
    }
  }
}
