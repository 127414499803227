












































































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { RegisterRequest } from '../../../models/Auth';
import { ApiError } from '../../../models/errors';
import { FieldStateMixin } from '../../../utils/FieldStateMixin';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import {
  email,
  required,
  numeric,
  sameAs,
  maxLength,
  minLength,
} from 'vuelidate/lib/validators';
import InvalidForm from '../../../components/shared/InvalidForm.vue';
import { selected } from '../../../utils/validators';

@Component({
  components: { InvalidForm },
  mixins: [FieldStateMixin],
  validations: {
    form: {
      email: { required, email },
      first_name: { required },
      last_name: { required },
      password: { required, minLength: minLength(8) },
      repeat_password: { required, sameAs: sameAs('password') },
      agreed: { required, selected },
    },
  },
})
export default class SignUp extends Mixins(FieldStateMixin) {
  public form: RegisterRequest = {
    email: null,
    first_name: null,
    last_name: null,
    password: null,
    repeat_password: null,
    agreed: null,
  };
  public apiErrors: ApiError | null = null;
  public busy: boolean = false;

  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation } & RegisterRequest;
    };

  public clearError(field: string) {
    if (this.apiErrors[field]) {
      this.apiErrors[field] = undefined;
    }
  }

  public submit(event: Event) {
    event.preventDefault();
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.busy = true;
      this.$recaptchaLoaded()
        .then(() =>
          this.$recaptcha('login').then((token) => {
            this.$http
              .post(this.$apiRoutes.auth.register, { ...this.form })
              .then((result: any) => {
                this.$router.push({ name: 'email-sent' });
              })
              .catch((error) => {
                if (error.response.status === 400) {
                  this.apiErrors = error.response.data;
                } else {
                  this.$root.$bvToast.toast(
                    'A apărut o eroare pe server și nu am putut salva contul tău. Te rugăm să încerci mai târziu.',
                    { variant: 'danger', title: 'Eroare', noAutoHide: true },
                  );
                }
                this.apiErrors = error.response.data;
              })
              .finally(() => {
                this.busy = false;
              });
          }),
        )
        .catch((data) =>
          this.$root.$bvToast.toast(
            'Nu am putut stabili dacă sunteți un bot sau nu. Vă rugăm să încercați mai târziu.',
            { variant: 'danger', noAutoHide: true },
          ),
        );
    }
  }
}
