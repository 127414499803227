










import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Choir } from '../../../models/Choir';
import { AxiosResponse } from 'axios';
import SummaryBox from '../SummaryBox.vue';
import { SummaryBoxContent } from '../../../models/SymmaryBox';

@Component({
  components: { SummaryBox },
})
export default class DashboardSummaryBox extends Vue {
  private static parseItems(items: SummaryResponse): SummaryBoxContent[] {
    const result: SummaryBoxContent[] = [];
    Object.keys(items).map((key: string) => {
      let type: string = null;
      let order: number;
      switch (key) {
        case 'members':
          type = 'Membri';
          order = 2;
          break;
        case 'conductors':
          type = 'Dirijori';
          order = 3;
          break;
        case 'events':
          type = 'Evenimente';
          order = 1;
          break;
      }
      if (type) {
        result.push({ type, order, count: items[key] });
      }
    });
    return result;
  }

  @Getter('selectedChoir') public selectedChoir: Choir;
  public items: SummaryBoxContent[] = [];
  public loading: boolean = true;

  @Watch('selectedChoir', { immediate: true })
  public updateSummaryBox() {
    if (this.selectedChoir) {
      this.loading = true;
      this.$http
        .get<SummaryResponse>(
          this.$apiRoutes.dashboard.summary(this.selectedChoir.id),
        )
        .then((response: AxiosResponse<SummaryResponse>) => {
          this.items = DashboardSummaryBox.parseItems(response.data);
        })
        .finally(() => (this.loading = false));
    }
  }

  public mounted() {
    this.items = DashboardSummaryBox.parseItems({
      members: 0,
      conductors: 0,
      waiting: 0,
      events: 0,
    });
  }
}

interface SummaryResponse {
  members: number;
  conductors: number;
  waiting: number;
  events: number;
}
