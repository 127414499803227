




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { VideoPlayerItem } from '../models/VideoGallery';
import { NewsVideoResponse } from '../models/responses/news';
import { AxiosResponse } from 'axios';

@Component
export default class VideoPlayer extends Vue {
  public videoData: VideoPlayerItem = null;
  public videoError = false;

  public mounted() {
    this.$http
      .get<NewsVideoResponse>(`api/videos/${this.$route.query.watchVideo}/`)
      .then((response: AxiosResponse<NewsVideoResponse>) => {
        this.videoData = {
          mp4: response.data.mp4,
          ogg: response.data.ogg,
          webm: response.data.webm,
        };
      })
      .catch(() => (this.videoError = true));
  }

  public close() {
    const query = {
      ...this.$route.query,
    };
    delete query.watchVideo;

    this.$router.replace({
      ...this.$router.currentRoute,
      query,
    });
  }
}
