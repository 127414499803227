










































































import { Component, Emit, Mixins, Vue, Watch } from 'vue-property-decorator';
import { FieldStateMixin } from '../utils/FieldStateMixin';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import InvalidForm from './shared/InvalidForm.vue';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { selected } from '../utils/validators';

@Component({
  components: { InvalidForm },
  validations: {
    form: {
      name: { required, minLength: minLength(5) },
      email: { required, email },
      message: { required, minLength: minLength(30) },
      gdpr: { selected },
    },
  },
})
export default class ContactForm extends Mixins(FieldStateMixin) {
  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { name: Validation; email: Validation; message: Validation };
    };
  public form: ContactFormModel = {
    name: null,
    email: null,
    message: null,
    gdpr: null,
  };
  public busy: boolean = false;
  public failed: boolean = false;
  @Watch('form') public formUpdate() {
    this.failed = false;
  }
  @Emit() public finish() {}

  public submitContactForm() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.busy = true;
      this.$http
        .post(this.$apiRoutes.messaging.contact, this.form)
        .then(() => {
          this.$root.$bvToast.toast('Mesajul a fost trimis cu succes', {
            title: 'Mesaj de contact',
            autoHideDelay: 5000,
            variant: 'success',
          });
          this.finish();
        })
        .catch(() => (this.failed = true))
        .finally(() => (this.busy = false));
    }
  }
}

interface ContactFormModel {
  name: string;
  email: string;
  message: string;
  gdpr: boolean;
}
