






















































import { Component, Vue } from 'vue-property-decorator';
import AddNewChoirStepTwo from '../../../../components/account/addChoir/AddNewChoirStepTwo.vue';
import AddNewChoirStepOne from '../../../../components/account/addChoir/AddNewChoirStepOne.vue';
import { VideoLesson } from '../../../../models/VideoLesson';
import { AxiosResponse } from 'axios';
import { MediaWrapper } from '../../../../modules/media/types';
import { Action } from 'vuex-class';
import * as mediaActions from '../../../../modules/media/actions';

@Component({
  components: { AddNewChoirStepTwo, AddNewChoirStepOne },
})
export default class VideoLessons extends Vue {
  public filter: string = null;
  public loading: boolean = false;
  public items: VideoLesson[] = [];

  public fields = [
    {
      key: 'title',
      label: 'Titlu',
      sortable: true,
      thClass: 'all-video-lessons-table-head',
    },
    {
      key: 'description',
      label: 'Descriere',
      sortable: true,
      thClass: 'all-video-lessons-table-head',
    },
    {
      key: 'action',
      label: 'Acțiuni',
      thClass: 'all-video-lessons-table-head',
    },
  ];
  @Action(mediaActions.LOAD_MEDIA) public loadMedia: (
    media: MediaWrapper,
  ) => void;

  public mounted() {
    this.loading = true;
    this.$http
      .get<VideoLesson[]>(this.$apiRoutes.videoLessons.list)
      .then((response: AxiosResponse<VideoLesson[]>) => {
        this.items = response.data;
      })
      .finally(() => (this.loading = false));
  }

  public playVideo(video: VideoLesson) {
    const media: MediaWrapper = {
      media: [
        {
          name: video.title,
          ogg: video.ogg,
          webm: video.webm,
          mp4: video.mp4,
          type: 'video',
        },
      ],
      name: video.title,
    };
    this.loadMedia(media);
  }
}
