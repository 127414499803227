import { Module } from 'vuex';
import { MediaState } from './types';
import { RootState } from '../../store';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: MediaState = {
  playing: false,
  playingMedia: null,
  playlist: null,
  showPlaylist: false,
};

const namespaced: boolean = false;

export const media: Module<MediaState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
