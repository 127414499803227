








































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import EditMemberModal from '../../../components/account/mychoir/EditMemberModal.vue';
import { Member } from '../../../models/Members';
import { Choir } from '../../../models/Choir';
import { County } from '../../../modules/definitions/types';
import { LoadingState } from '../../../modules/utils';

import * as userGetters from '../../../modules/user/getters';
import * as userActions from '../../../modules/user/actions';
import { User } from '../../../models/Auth';

@Component({
  components: {
    EditMemberModal,
  },
})
export default class MembersTable extends Vue {
  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;
  @Getter(userGetters.IS_CONDUCTOR) public isConductor: boolean;
  @Getter(userGetters.USER_DETAILS) public user: User;
  @Getter('counties') public counties: LoadingState<County[]>;
  @Action(userActions.LOAD_CHOIRS) public loadChoirs: () => void;
  public allMembers: Member[] = [];
  public selectedMember: Member = null;

  public filter: string = '';

  public get fields() {
    if (this.isConductor) {
      return [
        { key: 'avatar', label: 'Avatar' },
        { key: 'name', label: 'Nume', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'sex', label: 'Sex', sortable: true },
        { key: 'city', label: 'Localitate', sortable: true },
        { key: 'county', label: 'Județ', sortable: true },
        { key: 'birth_date', label: 'Data nașterii', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'edit', label: 'Acțiuni' },
      ];
    } else {
      return [
        { key: 'avatar', label: 'Avatar' },
        { key: 'name', label: 'Nume', sortable: true },
        { key: 'city', label: 'Localitate', sortable: true },
        { key: 'county', label: 'Județ', sortable: true },
      ];
    }
  }

  public get countyMap(): { [id: string]: string } {
    const counties: { [id: string]: string } = {};
    if (this.counties) {
      this.counties.data.forEach((county: County) => {
        counties[county.value] = county.text;
      });
    }
    return counties;
  }

  // PAGINATION
  public perPage: number = 10;
  public currentPage: number = 1;

  @Watch('selectedChoir', { immediate: true }) public onSelectedChoirUpdate() {
    if (this.selectedChoir && this.selectedChoir.id) {
      this.$http
        .get(this.$apiRoutes.choir.members(this.selectedChoir.id))
        .then((response: any) => {
          this.allMembers = response && response.data;
          if (this.selectedMember) {
            this.selectedMember = this.allMembers.find(
              (member: Member) => member.id === this.selectedMember.id,
            );
            if (!this.selectedMember) {
              this.$bvModal.hide('edit-member-modal');
            }
          }
        })
        .catch((error: any) => {});
    }
  }

  public mounted() {
    if (!this.$route.query.members) {
      this.$route.query.members = 'all';
    }
  }

  public switchTab(tab: string) {
    if (this.$route.query.members === tab) {
      return;
    }
    this.$router.push({
      query: { ...this.$route.query, members: tab },
    });
  }

  public get items(): Member[] {
    return this.allMembers
      .filter((member: Member) => (this.isConductor ? true : member.status))
      .filter((member: Member) => {
        switch (this.$route.query.members) {
          case 'all':
            return true;
          case 'conductors':
            return member.type === '1' && member.status;
          case 'singers':
            return member.type === '0' && member.status;
          case 'pending':
            return !member.status;
          default:
            return true;
        }
      })
      .sort((member: Member, otherMember: Member) => {
        return member.status ? 1 : -1;
      });
  }

  public rowClass(item: any) {
    if (item && !item.status) {
      return 'table-warning';
    }
  }

  public get pendingCount(): number {
    return this.allMembers.filter((item) => !item.status).length;
  }

  public get allCount(): number {
    return this.allMembers.filter((item) => item.status).length;
  }
  public get conductorsCount(): number {
    return this.allMembers.filter((item) => item.status && item.type === '1')
      .length;
  }
  public get singersCount(): number {
    return this.allMembers.filter((item) => item.status && item.type === '0')
      .length;
  }

  public editMember(member: Member) {
    this.selectedMember = member;
    this.$bvModal.show('edit-member-modal');
  }

  public canVerify(member: Member): boolean {
    return member.user_id !== this.user.id;
  }
}
