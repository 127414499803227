
























































































































































import { Component, Mixins, Vue } from 'vue-property-decorator';
import { minLength, required } from 'vuelidate/lib/validators';
import { Getter } from 'vuex-class';
import DateComp from '../dashboard/DateComp.vue';
import { Choir } from '../../../models/Choir';
import { FieldStateMixin } from '../../../utils/FieldStateMixin';
import InvalidForm from '../../shared/InvalidForm.vue';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';

@Component({
  components: { InvalidForm, DateComp },
  validations: {
    event: {
      title: { required, minLength: minLength(3) },
      startDate: { required },
      startTime: { required },
      endDate: { required },
      endTime: { required },
      type: { required },
      location: { required, minLength: minLength(3) },
      description: { required },
    },
  },
})
export default class CreateEvent extends Mixins(FieldStateMixin) {
  @Getter('selectedChoir') public selectedChoir: Choir;
  public options: Array<{
    value: number;
    text: string;
  }> = [
    {
      value: 1,
      text: 'Concert',
    },
    {
      value: 2,
      text: 'Repetiție',
    },
    {
      value: 3,
      text: 'Workshop',
    },
    {
      value: 4,
      text: 'Festival',
    },
    {
      value: 5,
      text: 'Alte acțiuni',
    },
  ];

  public $v: ValidationProperties<this> &
    ValidationGroups &
    Validation & {
      form: { [key: string]: Validation } & Event;
    };

  public event: Event = {
    id: null,
    title: null,
    start: null,
    end: null,
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    type: null,
    location: null,
    description: null,
    classes: null,
    cor: null,
  };

  public mounted() {
    this.$v.$reset();
  }

  public get start() {
    return (
      (this.event.startDate &&
        this.event.startTime &&
        new Date(`${this.event.startDate}T${this.event.startTime}`)) ||
      (this.event.startDate && new Date(this.event.startDate))
    );
  }

  public get end() {
    return (
      (this.event.endDate &&
        this.event.endTime &&
        new Date(`${this.event.endDate}T${this.event.endTime}`)) ||
      (this.event.endDate && new Date(this.event.endDate))
    );
  }

  public submit() {
    this.$v.$touch();
    this.event.cor = this.selectedChoir.id ? this.selectedChoir.id : 0;
    if (!this.$v.$anyError) {
      const event = {
        ...this.event,
        start: this.start,
        end: this.end,
      };
      this.$http.post(this.$apiRoutes.events.createEvent, event).then(() => {
        this.$root.$emit('created-event');
        this.$bvModal.hide('create-event');
      });
    }
  }
}

interface Event {
  id: number;
  title: string;
  start: Date;
  startDate: string;
  startTime: string;
  end: Date;
  endDate: string;
  endTime: string;
  type: number;
  location: string;
  description: string;
  classes: string;
  cor: number;
}
