



























import { Component, Vue } from 'vue-property-decorator';
import DateComp from '../dashboard/DateComp.vue';

@Component({
  components: { DateComp },
})
export default class EventDetails extends Vue {}
