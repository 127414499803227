























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Media, MediaWrapper } from '../modules/media/types';

@Component
export default class Playlist extends Vue {
  @Getter('getLoadedMedia') public loadedMedia: MediaWrapper;
  @Getter('playing') public nowPlaying: Media;
  @Action('playMedia') public playMedia: (media: Media) => {};

  public isNowPlaying(media: Media): boolean {
    return media.name === this.nowPlaying.name;
  }

  public selectMedia(media: Media): void {
    this.playMedia(media);
  }
}
