/* tslint:disable:variable-name max-classes-per-file */
export class MediaAppearance {
  public title?: string;
  public text?: string;
  public front_page_image?: string;
  public source_display?: string;
  public source_url?: string;
  public source_date?: string;
  public date?: string;
  public slug?: string;

  constructor(data: {
    title?: string;
    text?: string;
    front_page_image?: string;
    source_display?: string;
    source_url?: string;
    source_date?: string;
    date?: string;
    slug?: string;
  }) {
    this.title = data.title;
    this.text = data.text;
    this.front_page_image = data.front_page_image;
    this.source_display = data.source_display;
    this.source_url = data.source_url;
    this.source_date = data.source_date;
    this.date = data.date;
    this.slug = data.slug;
  }
}
