



















import { Component, Vue } from 'vue-property-decorator';
import CreateEvent from '../account/events/CreateEvent.vue';

@Component({
  components: { CreateEvent },
})
export default class CreateEventModal extends Vue {
  public $refs!: Vue['$refs'] & {
    createEvent: CreateEvent & { submit: () => void };
  };

  public mounted() {}

  public submit() {
    this.$refs.createEvent.submit();
  }
}
