















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ListItem from './ListItem.vue';
import ListActionComp from './ListActionComp.vue';
import { User } from '../../../models/Auth';
import { Choir } from '../../../models/Choir';
import { Score, ScoreMp3 } from '../../../models/Library';
import { MediaWrapper } from '../../../modules/media/types';
import BoxHeader from './BoxHeader.vue';

@Component({
  components: { ListItem, ListActionComp, BoxHeader },
})
export default class Scores extends Vue {
  @Getter('userDetails') public userDetails: User;
  @Getter('selectedChoir') public selectedChoir: Choir;
  @Prop({ default: 5 }) public count: number;
  @Action('loadMedia') public loadMedia: (media: MediaWrapper) => void;

  public scores: Score[] = [];
  public loading: boolean = true;
  public downloading: number[] = [];

  public mounted() {
    this.scores = [];
    this.loading = true;
    this.$http
      .get(this.$apiRoutes.dashboard.scores)
      .then((response: any) => {
        this.scores = response.data.slice(0, this.count);
      })
      .finally(() => (this.loading = false));
  }

  public getKey(score: Score) {
    return `${score.name}-${score.author}`;
  }

  public selectScore(score: Score) {
    this.loadMedia({
      name: score.name,
      media: score.mp3s.map((mp3: ScoreMp3) => {
        return {
          name: mp3.title,
          file: mp3.file,
          type: 'audio',
        };
      }),
    });
  }

  public downloadFile(score: Score) {
    this.downloading = [...this.downloading, score.id];
    this.$http
      .post(this.$apiRoutes.download('score', score.id), null, {
        responseType: 'blob',
      })
      .then((response: any) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${score.name}.zip`);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(() => {
        this.$bvToast.toast('Fișierul nu a putut fi descărcat', {
          title: 'Eroare',
          variant: 'danger',
        });
      })
      .finally(() => {
        this.downloading = this.downloading.filter(
          (item: number) => item !== score.id,
        );
      });
  }

  public isDownloading(score: Score) {
    return this.downloading.find((item: number) => item === score.id);
  }
}
