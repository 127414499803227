







































import { Component, Vue } from 'vue-property-decorator';
import SectionHeader from '../../../components/shared/SectionHeader.vue';
import Announcement from '../../../components/events/Announcement.vue';
import FullWidthTitle from '../../../components/shared/FullWidthTitle.vue';
import moment from 'moment';
import { AnnouncementWrapper } from '../../../models/announcements';

@Component({
  components: { FullWidthTitle, Announcement, SectionHeader },
})
export default class Announcements extends Vue {
  public loading = true;
  public serverError = false;
  public announcementWrappers: AnnouncementWrapper[] = null;
  public mounted() {
    this.$http
      .get(this.$apiRoutes.announcements)
      .then((response) => {
        this.announcementWrappers = response.data;
      })
      .catch(() => (this.serverError = true))
      .finally(() => {
        this.loading = false;
      });
  }

  public month(date: string): string {
    return moment(date).locale('ro').format('MMMM');
  }
}
