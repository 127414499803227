
































import { Component, Vue } from 'vue-property-decorator';
import { County, Locality, MapNumber } from '../../../models/Map';
import FullWidthTitle from '../../shared/FullWidthTitle.vue';
import MapController from './MapController.vue';
import { AxiosResponse } from 'axios';
const MapContainer = () => import('./MapContainer.vue');

@Component({
  components: { FullWidthTitle, MapController, MapContainer },
})
export default class Network extends Vue {
  public counties: County[] = [];
  public countyNumbers: MapNumber[] = null;
  public selectedCounty: string = null;
  public expandedCounty: string = null;

  public mounted() {
    this.$http
      .get<County[]>(this.$apiRoutes.choirs)
      .then((response: AxiosResponse<County[]>) => {
        const countyNumbers: MapNumber[] = [];
        response.data.forEach((countyData: County) => {
          countyNumbers.push({
            id: `RO-${countyData.abbrev}`,
            value: countyData.count,
          });
        });
        this.counties = response.data;
        this.countyNumbers = countyNumbers;
      });
  }

  public onMapClick(county: string) {
    this.selectedCounty = county;
  }

  public onCountyExpanded(county: string) {
    this.expandedCounty = county;
  }
}
