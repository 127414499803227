






















































































import { Component, Vue } from 'vue-property-decorator';
import { News } from '../../../models/News';

@Component
export default class NewsDetails extends Vue {
  public data?: News | null = null;

  public mounted() {
    this.$http
      .get(this.$apiRoutes.news.details(this.$route.params.slug))
      .then((result: any) => {
        this.data = new News(result.data);
      });
  }

  public get videoData() {
    return this.data && this.data.video;
  }
}
