




































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import UnconfirmedChoir from '../../../components/UnconfirmedChoir.vue';
import EventDetailsModal from '../../../components/events/EventDetailsModal.vue';
import DayDetails from '../../../components/events/DayDetails.vue';
import EventSignupModal from '../../../components/events/EventSignupModal.vue';
import NotifyChoirModal from '../../../components/events/NotifyChoirModal.vue';
import CreateEventModal from '../../../components/events/CreateEventModal.vue';
import { Getter } from 'vuex-class';
import { CalendarEvent } from '../../../models/Event';
import { Choir } from '../../../models/Choir';
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
import * as userGetters from '../../../modules/user/getters';
import { RedirectMixin } from '../../../utils/RedirectMixin';

@Component({
  components: {
    UnconfirmedChoir,
    DayDetails,
    CreateEventModal,
    EventDetailsModal,
    EventSignupModal,
    NotifyChoirModal,
    CalendarViewHeader,
    CalendarView,
  },
})
export default class Events extends Mixins(RedirectMixin) {
  private static getEventTypeText(type: number): string {
    switch (type) {
      case 1:
        return 'Concert';
      case 2:
        return 'Repetiție';
      case 3:
        return 'Workshop';
      case 4:
        return 'Festival';
      default:
        return 'Alte acțiuni';
    }
  }
  public showDate: Date = new Date();
  public selectedDay: Date = new Date();
  public events: CalendarEvent[] = [];

  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;
  @Watch('selectedChoir', { immediate: true }) public onSelectedChoirUpdate() {
    this.loadEvents();
  }

  public mounted() {
    this.$root.$on('created-event', this.loadEvents);
  }

  public setShowDate(d: Date) {
    this.showDate = d;
  }

  public selectDay(d: Date) {
    this.selectedDay = d;
  }

  private loadEvents() {
    this.events = [];
    if (this.selectedChoir && this.selectedChoir.id) {
      this.$http
        .get(this.$apiRoutes.events.eventsByChoir(this.selectedChoir.id))
        .then((response: any) => {
          const events: CalendarEvent[] = [];
          response.data.forEach((eventData: any) => {
            const event: CalendarEvent = {
              id: eventData.id,
              startDate: new Date(eventData.start),
              endDate: new Date(eventData.end),
              title: eventData.title,
              classes: eventData.global_event ? 'cm-event' : '',
              globalEvent: eventData.global_event,
              description: eventData.description,
              location: eventData.location,
              type: eventData.type,
            };
            event.type = Events.getEventTypeText(eventData.type);
            events.push(event);
          });
          this.events = events;
        });
    }
  }
}
