












































import { Component, Vue, Watch } from 'vue-property-decorator';
import ListActionComp from './ListActionComp.vue';
import BoxHeader from './BoxHeader.vue';
import { Getter } from 'vuex-class';
import ListItem from './ListItem.vue';
import { User } from '../../../models/Auth';
import { PendingAccount } from '../../../models/Dashboard';
import { Choir } from '../../../models/Choir';
import * as userGetters from '../../../modules/user/getters';

@Component({
  components: { ListItem, BoxHeader, ListActionComp },
})
export default class PendingAccounts extends Vue {
  @Getter(userGetters.USER_DETAILS) public userDetails: User;
  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;

  public pendingAccounts: PendingAccount[] = [];
  public loading: boolean = false;

  @Watch('selectedChoir', { immediate: true })
  public onSelectedChoirUpdate() {
    this.pendingAccounts = [];
    this.loading = true;
    if (this.selectedChoir.id === undefined) {
      return;
    }
    this.$http
      .get(this.$apiRoutes.dashboard.pending(this.selectedChoir.id))
      .then(
        (response: any) => {
          this.pendingAccounts = response.data;
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
        },
      );
  }
}
