
































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class Navbar extends Vue {
  @Getter('isAuthenticated') public isAuthenticated?: boolean;

  public openContact() {
    this.$root.$emit('openContact');
  }
}
