



































import { Component, Vue } from 'vue-property-decorator';
import VideoGalleryItemComp from './VideoGalleryItemComp.vue';
import NextPrevButtons from './NextPrevButtons.vue';
import { VideoGalleryItem } from '../../models/VideoGallery';
import { News } from '../../models/News';
import { NewsResponse } from '../../models/responses/news';

@Component({
  components: { VideoGalleryItemComp, NextPrevButtons },
})
export default class VideoGallery extends Vue {
  public galleryItems: VideoGalleryItem[] = null;
  public displayIndex: number = 0;

  public mounted() {
    this.$http
      .get<NewsResponse[]>('api/news/?category=video')
      .then((response) => {
        const items: VideoGalleryItem[] = [];
        response.data.forEach((item: NewsResponse) => {
          if (item.category === 'video' && item.video) {
            items.push({
              title: item.title,
              videoId: item.video.id,
              frontPageText: item.video.front_page_text,
              cover: item.front_page_image,
            });
          }
        });
        this.galleryItems = items;
      })
      .catch(() => {
        this.galleryItems = [];
      });
  }

  public nextVideo() {
    if (this.galleryItems) {
      this.displayIndex = (this.displayIndex + 1) % this.galleryItems.length;
    }
  }

  public previousVideo() {
    if (this.galleryItems) {
      this.displayIndex -= 1;
      if (this.displayIndex < 0) {
        this.displayIndex = this.galleryItems.length - 1;
      }
    }
  }
}
