





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Contest } from '../../../models/News';

@Component
export default class ContestTemplate extends Vue {
  @Prop() public data!: Contest;
}
