var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"content",attrs:{"fluid":""}},[_c('b-row',{staticClass:"top-image"}),_c('section-header',{attrs:{"id":"section-header","title":"Activități","description":"Bine ai venit în comunitatea Cantus Mundi! Află despre cele mai interesante experiențe culturale\n       de care se bucură membrii celei mai extinse mișcări naționale de integrare a copiilor prin muzică din România!"}})],1),_c('activity',{attrs:{"title":"Corurile","description":"Totul începe cu un copil care, indiferent de zona unde trăiește, are un vis: să aibă muzica în\n        viața lui. Programul Național Cantus Mundi există pentru acest copil: Cantus Mundi facilitează\n        legătura dintre copilul care își dorește să cânte și cel mai adecvat mediu pentru el - un cor și un\n        dirijor potrivit. Noi oferim toate instrumentele necesare pentru a conecta copiii și tinerii cu\n        dirijorii cei mai buni și ansambluri corale minunate, create în țară sau diaspora.","mission":"Fiecare cor de copii și tineri din România devine remarcabil prin frumusețea muzicii și prietenia\n    împărtășite în marea familie Cantus Mundi.","button":"Înscrie-te la audiții","image":require('../../../assets/images/activities-choir.jpg'),"image-position":"right"},on:{"action":function($event){return _vm.$router.push({ name: 'auditions' })}}}),_c('activity',{attrs:{"title":"Atelierele","description":'În fiecare an, sub deviza „Schimbăm România prin muzică!”, echipa Cantus Mundi organizează ' +
      'Atelierele Cantus Mundi în toată țara. Participarea este gratuită și oferă o oportunitate tuturor ' +
      'persoanelor care își doresc să învețe bazele tehnicii dirijorale, dar și tinerilor și copiilor care ' +
      'visează să cânte într-un un cor. Dacă vrei să creezi și să îndrumi un cor, alături de specialiștii ' +
      'Corului Madrigal vei descoperi noțiunile dirijatului coral, dar și modalități optime de a coordona ' +
      'un cor, cum creezi un repertoriu, precum și elemente de psihopedagogie și comunicare.',"mission":'Vino la Ateliere Cantus Mundi să descoperi bucuria de a educa și comunica prin muzică cu cei ' +
      'mai buni profesioniști ai artei corale din România!',"button":'Înscrie-te la atelier',"image":require('../../../assets/images/activities-workshop.jpg'),"image-position":"left"},on:{"action":function($event){return _vm.$router.push({ name: 'workshop' })}}}),_c('activity',{attrs:{"title":'Galele',"description":'La Galele Cantus Mundi, membrii comunității Cantus Mundi urcă împreună pe aceeași scenă, un ' +
      'spațiu multicultural, unde toți sunt egali și importanți. Gala națională Cantus Mundi este un ' +
      'spectacol special, care reunește mii de copii din coruri din toată țara, care ajung să comunice ' +
      'prin muzică. În fiecare an, ei au ocazia de a cânta alături de membrii faimosului Cor Madrigal. ' +
      'Totodată, fiecare cor are ocazia de a participa la Galele locale, organizate periodic, conectând ' +
      'publicul de acasă cu frumusețea cântului coral.',"mission":'La Galele Cantus Mundi, copiii și tinerii coriști din toată țara au posibilitatea să strălucească ' +
      'împreună în lumina reflectoarelor, pe scenele din București și din țară.',"button":'Vreau gală în orașul meu',"image":require('../../../assets/images/activities-gala.jpg'),"image-position":"right"},on:{"action":_vm.openContact}}),_c('activity',{attrs:{"title":'Festivalurile',"description":'Ne dorim ca toate corurile Cantus Mundi să aibă acces la un mediu favorabil comunicării și ' +
      'dezvoltării. Cunoscându-se, interacționând, învățând unii de la alții, prezentându-se unui public ' +
      'cât mai variat, copiii și tinerii din corurile înscrise în Programul Național Cantus Mundi cresc ' +
      'exponențial. De aceea, participăm la tot mai multe evenimente tematice, iar prezența la cele ' +
      'mai grozave festivaluri alături de artiști celebri a devenit o constantă pentru activitatea Cantus ' +
      'Mundi.',"mission":'Prezența corurilor Cantus Mundi în marile festivaluri din România este o sursă de inspirație și ' +
      'de energie atât pentru copii și tinerii participanți, cât și pentru publicul prezent.',"button":'Vreau să particip la festival',"image":require('../../../assets/images/activities-fest.jpg'),"image-position":"left"},on:{"action":_vm.openContact}}),_c('activity',{attrs:{"title":'Turneele',"description":'Mii de copii din Programul Național Cantus Mundi ajung să cânte pe marile scene, fie că este ' +
      'vorba despre evenimente naționale sau internaționale. Dorim să îi cunoască o lume întreagă: să ' +
      'fie ascultați, să fie aplaudați și să se integreze în această mare comunitate muzicală, unde ' +
      'valoarea umană și cea profesională sunt prețuite și încurajate, pentru un viitor mai bun pentru ' +
      'fiecare corist. Încheiem parteneriate prestigioase, care se concretizează în evenimente de ' +
      'anvergură: Turneul Internațional Stradivarius 2018 susținut de celebrul violonist Alexandru ' +
      'Tomescu și pianista Angela Drăghicescu, 25.º FESTIVAL INTERNACIONAL DE MÚSICA DEGAIA, ' +
      'ș.a.m.d.',"mission":'Susținem și onorăm performanța în muzică! Oricare dintre corurile Cantus Mundi are ' +
      'oportunitatea să participe la turnee prestigioase, alături de muzicieni cunoscuți.',"button":'Vreau concert în orașul meu',"image":require('../../../assets/images/activities-tour.jpg'),"image-position":"right"},on:{"action":_vm.openContact}}),_c('activity',{attrs:{"title":'Taberele',"description":'Programul Național Cantus Mundi, împreună cu partenerii săi, organizează în fiecare an ' +
      'taberele gratuite Cantus Mundi Camp, care se adresează tututor copiilor și tinerilor atât din ' +
      'București, cât și din țară, într-un demers incluziv de promovare a educației culturale în rândul ' +
      'tinerei generații. Atelierele și cursurile interactive de educație muzicală și artistică, dar și ' +
      'activitățile de dezvoltare personală, coordonate de echipele de specialiști Cantus Mundi, susțin ' +
      'evoluția armonioasă a copiilor și tinerilor din punct de vedere intelectual, emoțional și social.',"mission":'Cantus Mundi Camp creează un univers al prieteniei, al muzicii și al acceptării celuilalt, deschis ' +
      'copiilor și tinerilor, indiferent de geografie sau capacități.',"button":'Vreau tabără în orașul meu',"image":require('../../../assets/images/activities-camp.jpg'),"image-position":"left"},on:{"action":_vm.openContact}}),_c('activity',{attrs:{"title":"Școala de Vară","description":'Școala de Vară Cantus Mundi se adresează tuturor copiilor dornici să-și exploreze creativitatea ' +
      'prin intermediul muzicii, a artelor spectacolului și al altor activități educative și culturale. ' +
      'Organizată la București sau local, în timpul vacanței de vară, Școala de Vară oferă copiilor ' +
      'ateliere gratuite de muzică corală, jocuri muzicale, istoria muzicii, improvizație muzicală, ' +
      'actorie, percuție, nutriție și mișcare scenică, dezvoltare personală și dezbatere academică, sub ' +
      'îndrumarea formatorilor Cantus Mundi, profesioniști din lumea muzicii și a artelor spectacolului ' +
      'sau specialiști din psihologia copilului și comunicare.',"mission":'Școala de Vară Cantus Mundi reprezintă o ofertă educațională de elită, care susține dezvoltarea ' +
      'copiilor pe mai multe niveluri, prin joc și procese interactive „learning by doing”.',"button":'Vreau Școala de Vară în orașul meu',"image":require('../../../assets/images/activities-summer-school.jpg'),"image-position":"right"},on:{"action":_vm.openContact}}),_c('call-to-action')],1)}
var staticRenderFns = []

export { render, staticRenderFns }