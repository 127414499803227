import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
  faCloudDownloadAlt,
  faComment,
  faEllipsisH,
  faArrowUp,
  faEllipsisV,
  faFileAlt,
  faFilePdf,
  faList,
  faFilter,
  faMusic,
  faPauseCircle,
  faPlay,
  faPlayCircle,
  faPlus,
  faSearch,
  faStepBackward,
  faStepForward,
  faThLarge,
  faExpandArrowsAlt,
  faTimes,
  faUsers,
  faUserEdit,
  faVolumeMute,
  faVolumeUp,
  faBars,
  faSignOutAlt,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faCloudDownloadAlt);
library.add(faFilePdf);
library.add(faPlus);
library.add(faTimes);
library.add(faThLarge);
library.add(faCalendarAlt);
library.add(faFilter);
library.add(faMusic);
library.add(faFileAlt);
library.add(faComment);
library.add(faUsers);
library.add(faUserEdit);
library.add(faSearch);
library.add(faExpandArrowsAlt);
library.add(faEllipsisH);
library.add(faEllipsisV);
library.add(faPlayCircle);
library.add(faPlay);
library.add(faArrowUp);
library.add(faPauseCircle);
library.add(faStepBackward);
library.add(faStepForward);
library.add(faVolumeUp);
library.add(faVolumeMute);
library.add(faList);
library.add(faBars);
library.add(faSignOutAlt);
library.add(faWindowClose);
Vue.component('font-awesome-icon', FontAwesomeIcon);
