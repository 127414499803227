import Vue from 'vue';
import {
  AspectPlugin,
  ToastPlugin,
  ModalPlugin,
  VBTogglePlugin,
  NavbarPlugin,
  TabsPlugin,
  NavPlugin,
  FormPlugin,
  LayoutPlugin,
  InputGroupPlugin,
  TablePlugin,
  PaginationPlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  FormInputPlugin,
  BadgePlugin,
  SpinnerPlugin,
  ProgressPlugin,
  FormSelectPlugin,
  FormGroupPlugin,
  ListGroupPlugin,
  FormTextareaPlugin,
  LinkPlugin,
  AlertPlugin,
  FormCheckboxPlugin,
  CardPlugin,
  FormRadioPlugin,
  CarouselPlugin,
  FormDatepickerPlugin,
  FormTimepickerPlugin,
  SidebarPlugin,
  AvatarPlugin,
  OverlayPlugin,
} from 'bootstrap-vue';

Vue.use(AspectPlugin);
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.use(VBTogglePlugin);
Vue.use(NavbarPlugin);
Vue.use(NavPlugin);
Vue.use(FormPlugin);
Vue.use(LayoutPlugin);
Vue.use(InputGroupPlugin);
Vue.use(TablePlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(PaginationPlugin);
Vue.use(FormInputPlugin);
Vue.use(BadgePlugin);
Vue.use(SpinnerPlugin);
Vue.use(ProgressPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormGroupPlugin);
Vue.use(ListGroupPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(LinkPlugin);
Vue.use(AlertPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(CardPlugin);
Vue.use(TabsPlugin);
Vue.use(FormRadioPlugin);
Vue.use(CarouselPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormTimepickerPlugin);
Vue.use(SidebarPlugin);
Vue.use(AvatarPlugin);
Vue.use(OverlayPlugin);
