
















































import { Component, Vue } from 'vue-property-decorator';
import ListActionComp from '../../../components/account/dashboard/ListActionComp.vue';
import ListItem from '../../../components/account/dashboard/ListItem.vue';
import BoxHeader from '../../../components/account/dashboard/BoxHeader.vue';

@Component({
  components: { BoxHeader, ListItem, ListActionComp },
})
export default class PosterResourcesBox extends Vue {}
