






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Choir } from '../../../models/Choir';
import EditChoirModal from './EditChoirModal.vue';

import * as userGetters from '../../../modules/user/getters';

@Component({
  components: {
    EditChoirModal,
  },
})
export default class ChoirPresentationBox extends Vue {
  @Getter(userGetters.SELECTED_CHOIR) public selectedChoir: Choir;
  @Getter(userGetters.IS_CONDUCTOR) public isConductor: boolean;
}
