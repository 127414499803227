





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkshopDate } from '../../../models/workshop';

@Component
export default class WorkshopDetails extends Vue {
  @Prop() public date: WorkshopDate | null;
  @Prop({ default: true }) public loading: boolean;
}
